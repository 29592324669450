import React, {useState} from 'react'
import "./TreeSegmentDashboardToolBar.css";
import {SegmentTopRightBlockActions} from "../../../helpers/SegmentationHelpers"
// import {Input} from "@progress/kendo-react-inputs";
import {
    Button,
    Toolbar,
    ToolbarItem,
    ToolbarSpacer,
    ToolbarSeparator, DropDownButton, DropDownButtonItem
} from "@progress/kendo-react-buttons";
import { Tooltip } from "@progress/kendo-react-tooltip";
// import { DropDownList } from "@progress/kendo-react-dropdowns";
import {
    DELETE_MODE_MULTI, DOWNLOAD_PROFILE,
    EDIT_SEGMENT_ROUTE, SEGMENT_DEACTIVATE_API,
    SEGMENT_ID_PLACE_HOLDER, SEGMENT_REPORT_REGENERATE_API,
    SEGMENTS_REPORT_DETAIL, SUCCESS_STATUS
} from "../../../api/constants";
// import moment from "moment";
import {NavLink,useHistory} from "react-router-dom";
import {semcastAxios, showErrorToastMessage, showSuccessToastMessage} from "../../../Utils/Common";
import {Pager} from "@progress/kendo-react-data-tools";
const TreeSegmentDashboardToolBar = ({
                                    actionSubscriberOfTopRightBlock,
                                    loadItems,
                                    profileFormik,
                                    segmentName,
                                    accessibleGroups,
                                    accessibleUsers,
                                    identifyDeleteMode,
    reloadAfterAction,
    items
                                    // onChangeStatus
                                }) => {
    const [rightTopSelection, setRightTopSelection] = useState({status:"",group:"",user:""});
    const [searchSegmentName, setSearchSegmentName] = useState("");
    const [statusValue, setStatusValue] = useState("All");
    const [disabled, setDisabled] = useState(false);
    const validServiceTypes = ["BUSINESS", "CONSUMER"];
    const segmentTypes = ["segment","matchback-segment","tag-segment",];
    const consumerSubTypes = ["CONSUMER","CONSUMER-VISITOR","CONSUMER-SERVED","CONSUMER-REMAINING","IDX-CONSUMER"];
    const businessSubTypes = ["BUSINESS","BUSINESS-VISITOR","BUSINESS-SERVED","BUSINESS-REMAINING","IDX-BUSINESS"];
    const activationTypes = ["Activation","Impression-Activation"]
    const segment1Status = profileFormik?.values?.selectedSegments[0]?.status;
    const segment2Status = profileFormik?.values?.selectedSegments[1]?.status;
    const segment1SubType = profileFormik?.values?.selectedSegments[0]?.subType;
    const segment2SubType = profileFormik?.values?.selectedSegments[1]?.subType;
    const segment1ObjectType = profileFormik?.values?.selectedSegments[0]?.objectType;
    const segment2ObjectType = profileFormik?.values?.selectedSegments[1]?.objectType;
    let selectedSegmentsLength = profileFormik?.values?.selectedSegments?.length;
    let history = useHistory();

    const canEnableAppendReplaceButton = () => {
        //Condition:
        // 1. selected segments length has to be 2
        // 2. selected segments subType has to be the same
        // 3. selected segments objectType has to be one of the segment types: segment,matchback-segment,tag-segment
        // 4. one of the selected segments have to be activated by a platform
        // 5. selected segments status has to be AVAILABLE/COMPLETED
        // 6. cannot append/replace site-visitor-tag types
        // NOTE: We are not using the activation end data (campaign_range_end) in the requirements check.
        const segment1Children = profileFormik?.values?.selectedSegments[0]?.children;
        const segment2Children = profileFormik?.values?.selectedSegments[1]?.children;
        let segmentChildren = (children) => {
            if (!children || children.length === 0) return false;
            let conditionMet = false;
            for (let i = 0; i < children.length; i++) {
                if (children[i].objectType !== "Activation") {
                    continue;
                }
                if (children[i].status !== "COMPLETED") {
                    continue;
                }
                conditionMet = true;
            }
            return conditionMet;
        }
        try {
            if (selectedSegmentsLength === 2) {
                if ((segment1Status === 'AVAILABLE' || segment1Status === 'COMPLETED')
                    && (segment2Status === 'AVAILABLE' || segment2Status === 'COMPLETED')
                    && segment1SubType === segment2SubType
                    && validServiceTypes.includes(segment1SubType.toUpperCase())
                    && (segmentChildren(segment1Children) || segmentChildren(segment2Children))
                    && segmentTypes.includes(segment1ObjectType.toLowerCase()) && segmentTypes.includes(segment2ObjectType.toLowerCase())) {
                    return true;
                }
            } else {
                return false;
            }
        } catch (e) {
            return false;
        }
    }

    const canEnableLookalikeSegmentButton = ()=>{
        //Condition:
        // 1. selected segment length has to be 1
        // 2. selected segment subType has to be consumerSubTypes
        // 3. selected segment objectType has to be segmentTypes
        // 4. selected segment status has to be AVAILABLE/COMPLETED
        try {
            if ((segment1Status === 'AVAILABLE' || segment1Status === 'COMPLETED')
                && selectedSegmentsLength === 1
                && consumerSubTypes.includes(segment1SubType.toUpperCase())
                && segmentTypes.includes(segment1ObjectType.toLowerCase())) {
                return true;
            } else {
                return false;
            }
        } catch (e) {
            return false
        }
    }
    const canEnableEditSegmentButton = () => {
        //Condition:
        // 1. selected segment length has to be 1
        // 2. selected segments subType has to be CONSUMER/BUSINESS
        // 3. selected segments status has to be AVAILABLE/COMPLETED
        try {
            if ((segment1Status === 'AVAILABLE' || segment1Status === 'COMPLETED') && selectedSegmentsLength === 1 &&
                (segment1ObjectType === 'Segment' && (segment1SubType === 'Consumer' || segment1SubType === 'Business')) ||
                (segment1ObjectType === 'IDX-Segment' && (segment1SubType === 'IDX-Consumer' || segment1SubType === 'IDX-Business'))
            ) {
                localStorage.setItem("selectedSegmentId", profileFormik?.values?.selectedSegments[0].id);
                return true;
            } else {
                localStorage.removeItem("selectedSegmentId");
                return false;
            }
        } catch (e) {
            return false
        }
    }

    const canEnableDeactivateButton = () => {
        //Condition:
        // 1. the selected item needs to be an Activation or Impression-Activation.
        // 2. The activation status needs to be COMPLETED.
        // 3. User can select one or more activations and deactivate all at once.
        let status = [];
        let invalidTypes = [];
        // console.log(profileFormik.values.selectedSegments);
        profileFormik.values.selectedSegments.forEach(function (v) {
            if(v.status!== 'COMPLETED')   status.push(v.status);
            if(v.objectType  !== "Activation" && v.objectType !== "Impression-Activation"){
                invalidTypes.push(v.objectType);
            }
        });
        try {
            return selectedSegmentsLength > 0 && status.length === 0 && invalidTypes.length === 0;
        } catch (e) {
            return false
        }
    }
    const canEnableProfileReportButton = () => {
        //Condition:
        // 1. selected segment length has to be 1
        // 2. selected segments subType has to be CONSUMER/BUSINESS
        // 3. selected segments status has to be AVAILABLE/COMPLETED
        try {
            if ((segment1Status === 'AVAILABLE' || segment1Status === 'COMPLETED') && selectedSegmentsLength === 1 &&
                (segment1ObjectType === 'Segment' || segment1ObjectType === 'Matchback-Segment' || segment1ObjectType === 'Tag-Segment' || segment1ObjectType === 'IDX-Segment')) {
                localStorage.setItem("selectedSegmentId", profileFormik?.values?.selectedSegments[0].id);
                return true;
            } else {
                localStorage.removeItem("selectedSegmentId");
                return false;
            }
        } catch (e) {
            return false
        }
    }
    const canEnableCopySegmentButton = ()=>{
        //Condition:
        // 1. selected segment length has to be 1
        // 2. selected segments subType can be any segment type
        // 3. selected segments status has to be AVAILABLE/COMPLETED
        try {
            if ((segment1Status === 'AVAILABLE' || segment1Status === 'COMPLETED') && selectedSegmentsLength === 1 && segmentTypes.findIndex(element => {return element.toLowerCase() === segment1ObjectType.toLowerCase() }) >= 0) {
                localStorage.setItem("selectedSegmentId", profileFormik?.values?.selectedSegments[0].id);
                return true;
            } else {
                localStorage.removeItem("selectedSegmentId");
                return false;
            }
        } catch (e) {
            return false
        }
    }

    // console.log("Selected Segments", profileFormik.values.selectedSegments);
    const canEnableMergeSegmentButton = ()=>{
        //Condition:
        // 1. selected segment length has to be 2
        // 2. selected segments subType has to be the same
        // 3. selected segments status has to be AVAILABLE/COMPLETED
        // 4. selected segments objectType has to be segmentTypes
        // 5. at least 2 selected segments must have a count > 0.
        let status = [];
        let invalidTypes = [];
        let selectedTypes = [];
        let cntNot0 = 0;

        let checkSubset = (parentArray, subsetArray) => {
            // segmentTypes.findIndex(element => {return element.toLowerCase() === segment1ObjectType.toLowerCase() }) >= 0
            return subsetArray.every((el) => {
                return parentArray.includes(el.toUpperCase())
            })
        }
        try {
            profileFormik?.values?.selectedSegments?.forEach(function (v) {
                if(v.status!== 'AVAILABLE' &&  v.status!== 'COMPLETED' &&  v.status!== 'ACTIVE')   status.push(v.status);
                if(activationTypes.includes(v.objectType)) invalidTypes.push(v.objectType);
                selectedTypes.push(v.subType);
                if (v.objectSize > 0) cntNot0++;
            });

            if (selectedSegmentsLength > 1 && cntNot0 > 1 && status.length === 0 && invalidTypes.length === 0 && (checkSubset(consumerSubTypes,selectedTypes) || checkSubset(businessSubTypes, selectedTypes))) {
                   return true;
            } else {
                return false;
            }
        } catch (e) {
            return false;
        }
    }

    const canEnableDeleteSegmentButton = ()=>{
        //Condition:
        // 1. selected segments status have to be AVAILABLE/COMPLETED/ACTIVE/ERROR
        // 2. selected segments objectType cannot be activation/impression-activation (for proper billing).
        let status = [];
        let invalidTypes = [];

        profileFormik.values.selectedSegments.forEach(function (v) {
            if(v.status!== 'AVAILABLE' && v.status!== 'COMPLETED' && v.status!== 'ACTIVE' && v.status!== 'ERROR')   status.push(v.status);
            if(activationTypes.includes(v.objectType)) invalidTypes.push(v.objectType);
        });
        try {
            return selectedSegmentsLength > 0 && status.length === 0 && invalidTypes.length === 0;
        } catch (e) {
            return false
        }
    }

    const canEnableSplitSegmentButton = () => {
        //Condition:
        // 1. selected segment length has to be 1
        // 2. selected segments objectSize has to be >= 100
        // 3. selected segments status has to be AVAILABLE/COMPLETED
        // 4. selected segments objectType has to be segmentTypes

        const size = profileFormik?.values?.selectedSegments[0]?.objectSize;
        try {
            // let splitSegment = false;
            if (selectedSegmentsLength === 1
                && (segment1Status === 'AVAILABLE' || segment1Status === 'COMPLETED')
                && size >= 100
                && segmentTypes.includes(segment1ObjectType.toLowerCase())) {
                return true;
            } else {
                return false;
            }
        } catch(e) {
            return false;
        }
    }

    const canEnableIdentityReportButton = () => {
        //Condition:
        // 1. selected segment length has to be 1
        // 2. selected segment objectType has to be site visitor tag or activation
        // 3. selected segment status has to be ACTIVE (site visitor tag) COMPLETED (activation)
        // 4. selected segment identityReport has to be true

        try {
            if (selectedSegmentsLength === 1
                && ((segment1ObjectType === "Site-Visitor-Tag" && segment1Status === 'ACTIVE')
                || (segment1ObjectType === "Activation" && segment1Status  === "COMPLETED"))
                && profileFormik?.values?.selectedSegments[0].identityReport === true) {
                return true;
            } else {
                return false;
            }
        } catch(e) {
            return false;
        }
    }

    const canEnableSynchronizeButton = () => {
        //Condition:
        // 1. selected type must be an activation.
        // 2. selected activation must be a child of a regular segment.
        // 3. selected activation must be a child of a Segment Type, whom is a child of a IDX-Segment.
        // 4. selected activation number must be 1.


        let status = [];
        let invalidTypes = [];
        let foundId;
        let targetId = profileFormik.values.selectedSegments[0]?.parentId;

            // activation must be a child of a Segment Type, whom is a child of a IDX-Segment.
        for (const child of items) { //check all dashboard items
            if(child.children.length > 0){ //if any item has children
                for (const item of child.children) { //loop through children
                    if(item.objectType === "IDX-Segment"){ // check if theres any IDX-Segment
                        if(item.children.length > 0){ //and if this IDX-Segment has children
                            item.children.forEach(index => { //loop through children to find matching id
                                if(index.objectType.toString() === "Segment" && index.id === targetId){ //match segment id to selected activation's parent id
                                    foundId = index.id; //found matched id
                                }
                            })
                        }
                    }
                }
            }
        }

        profileFormik.values.selectedSegments.forEach(function (v) {
            if(v.status!== 'COMPLETED')   status.push(v.status);
            if(v.objectType  !== "Activation" && v.objectType !== "Impression-Activation"){
                invalidTypes.push(v.objectType);
            }
        });
        try {
            return (selectedSegmentsLength === 1 && status.length === 0 && invalidTypes.length === 0 && foundId === targetId)
        } catch (e) {
            return false
        }
    }

    //Enable if we decide to put back More Options button on toolbar
    // const canEnableMoreOptionsButton = () => {
    //     //Condition:
    //     // 1. One of Look-alike, Deactivate or Synchronize must be enabled
    //     if (canEnableLookalikeSegmentButton() || canEnableDeactivateButton() || canEnableSynchronizeButton()) {
    //         return true;
    //     }
    //     return false;
    // }

    function getAllIds(obj) {
        let ids = [];

        // Helper function to recursively extract ids
        function extractIds(currentObj) {
            // Add the current object's id to the ids array
            if (currentObj.id !== undefined) {
                ids.push(currentObj.id);
            }

            // Check if the current object has children and recurse
            if (Array.isArray(currentObj.children)) {
                currentObj.children.forEach(child => extractIds(child));
            }
        }

        // Start the extraction with the main object
        extractIds(obj);

        return ids;
    }
    const canEnableIdentityIDXButton = () => {
        //Condition:
        // 1. selected type must be a segment or IDX-Tag.
        // 2. if selected a segment, must be a child of an IDX-Tag.
        // 3. number of selected item must be 1.

        let status = [];
        let invalidTypes = [];
        let allIds = [];
        // activation must be a child of a Segment Type, whom is a child of a IDX-Tag.
        for (const child of items) { //check all dashboard items
            if(child.objectType === 'IDX-Tag' && child.children.length > 0){ //is IDX Tag and has children
                 allIds.push(...getAllIds(child)); //getting all ids under an IDX-Tag in the current page
            }
        }

        const foundId = allIds.find(item=>item === profileFormik.values.selectedSegments[0]?.id) //proof of selected id is a child of an IDX Tag
        profileFormik.values.selectedSegments.forEach(function (v) {
            if(v.status!== 'AVAILABLE' && v.status!== 'ACTIVE')   status.push(v.status);
            if(v.objectType  !== "Segment" && v.objectType !== "IDX-Segment" && v.objectType !== "IDX-Tag"){
                invalidTypes.push(v.objectType);
            }
        });
        try {
            return (selectedSegmentsLength === 1 && status.length === 0 && invalidTypes.length === 0 && foundId)
        } catch (e) {
            return false
        }
    }


    const handleGenerateReportBtn = async (segmentId) => {
        try {
            setDisabled(true);
            let response = await semcastAxios.post(SEGMENT_REPORT_REGENERATE_API + segmentId + '/regenerate' , {
                withCredentials: true,
            });
            if (response.data.status === SUCCESS_STATUS) {
                showSuccessToastMessage("Regenerating Report", 30);
                setDisabled(false);
                return response.data;
            }
        } catch (error) {
            if(error.response.status === 401 || error.response.status === 403){
                showErrorToastMessage(error + " You did not have the permission to proceed, please contact support", 15);
            }else{
                showErrorToastMessage(error, 15);
            }
            setDisabled(false);
        }finally {
            reloadAfterAction();
        }
    }
    function convertUTCDateToLocalDate(date) {
        return new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
    }
    const handleGetReportBtn = async (segmentId, profileDataObsolete) => {
        setDisabled(true);
        // 1. check profile_data_obsolete is true or false
        if (profileDataObsolete) {
            await handleGenerateReportBtn(segmentId);
        } else {
            // 2. if profile_data_obsolete is false, check if profile json data is null or not
            try{
                // let response = await semcastAxios.get(DOWNLOAD_PROFILE.replace(SEGMENT_ID_PLACE_HOLDER, segmentId), {withCredentials: true, responseType: 'blob'}) //Specify blob file type
                // 3. profile json data is not null, go to profile page
                // if (response.status === 200) {
                    try {
                        let response = await semcastAxios.get(SEGMENTS_REPORT_DETAIL.replace(SEGMENT_ID_PLACE_HOLDER, segmentId), {withCredentials: true})
                        if (response.data.status === SUCCESS_STATUS) {
                            // showSuccessToastMessage("Getting Report", 10);
                            setDisabled(false);
                            return (
                                history.push(response.data.data.segment_type === "CONSUMER" ? (
                                    {
                                        pathname: `/dashboard`,
                                        state: {
                                            notes: response.data.data.notes, // BKR
                                            segment_name: response.data.data.segment_name,
                                            data: response.data.data.profile_report_details,
                                            segment_id: response.data.data.id,//MZHU
                                            profile_data_obsolete: response.data.data.profile_data_obsolete, //FD#11503
                                        }
                                    }
                                ) : (
                                    {
                                        pathname: `/business-dashboard`,
                                        state: {
                                            notes: response.data.data.notes, // BKR
                                            segment_name: response.data.data.segment_name,
                                            data: response.data.data.profile_report_details,
                                            segment_id: response.data.data.id,//MZHU
                                            profile_data_obsolete: response.data.data.profile_data_obsolete, //FD#11503
                                        },
                                    }
                                ))
                            )
                        }
                    } catch (error) {
                        if(error.response.status === 401 || error.response.status === 403){
                            showErrorToastMessage(error + " You did not have the permission to proceed, please contact support", 15);
                        }else{
                            showErrorToastMessage(error,15);
                        }
                    }
                // }
            } catch (error) {
                // 3. profile json data is null, backend will trigger regenerate process, user stays on Dashboard
                if(error.response.status === 404){
                    showErrorToastMessage("404: Missing mandatory JSON object: The file is out of date, please try again after your profile report regenerate completes.", 10);
                }else if (error.response.status === 500){
                    showErrorToastMessage("500: Internal error, please try again later", 10);
                }
            } finally {
                reloadAfterAction();
            }
        }
    }

    const deactivateSegments = async (itemId) => {
        // setIsLoaded(false);
        // deactivateSegment(itemId).then((result) => {
        // showSuccessToastMessage("Segment Deactivate Request Sent.");
        // props.loadItems();
        // setIsLoaded(true);
        // });

        try {
            setDisabled(true);
            let response = await semcastAxios.patch(SEGMENT_DEACTIVATE_API.replace(SEGMENT_ID_PLACE_HOLDER, itemId), {withCredentials: true});
            if (response.data.status === SUCCESS_STATUS) {
                showSuccessToastMessage("Deactivate Request Sent", 15);
                setDisabled(false);
                // props.loadItems();
            }
        } catch (error) {
            if(error.response.status === 401 || error.response.status === 403){
                showErrorToastMessage(error + " You did not have the permission to proceed, please contact support", 15);
            }else if(error.response.status === 500){
                showErrorToastMessage(error.response.data.data + " Please contact support", 15);
            }

            setDisabled(false);

        }
    };

    const onChangeStatus = (event) => {
        const status = event.target.value;
        sessionStorage.setItem("dropDownStatus", status);
        setStatusValue(status);
        const rightTopObject={status:status,group:rightTopSelection.group,user:rightTopSelection.user};
        setRightTopSelection(rightTopObject);
        loadItems(searchSegmentName, rightTopObject);
    };

    const onChangeGroup = (event) => {
        const group = event.target.value.id;
        sessionStorage.setItem("dropDownGroup", group);
        const rightTopObject={status:rightTopSelection.status,group:group,user:rightTopSelection.user};
        setRightTopSelection(rightTopObject);
        loadItems(searchSegmentName, rightTopObject);
    };

    const onChangeUser = (event) => {
        const user = event.target.value.id;
        sessionStorage.setItem("dropDownUser", user);
        const rightTopObject={status:rightTopSelection.status,group:rightTopSelection.group,user:user};
        setRightTopSelection(rightTopObject);
        loadItems(searchSegmentName, rightTopObject);
    };

    const  actionSubscriberOfTopIconRow = (action: SegmentTopRightBlockActions)=> {
        actionSubscriberOfTopRightBlock(action)
    };

    const actionTrigger = (action:SegmentTopRightBlockActions) =>{
        actionSubscriberOfTopIconRow(action)
    };

    const moreOptions = [
        {
            text: "Look-alike",
            iconClass: "fa fa-star-half-o",
            disabled: !canEnableLookalikeSegmentButton(),
        },
        {
            text: "Deactivate",
            icon: "pause",
            disabled: !canEnableDeactivateButton(),
        },
        {
            text: "Synchronize",
            iconClass: "fa fa-sync",
            disabled: !canEnableSynchronizeButton(),
        }

    ];

    const moreOptionsHandler = (e) => {
        if (e.item.text === "Look-alike") {
            actionTrigger(SegmentTopRightBlockActions.LOOK_LIKE);
        } else if (e.item.text === "Deactivate") {
            actionTrigger(SegmentTopRightBlockActions.DEACTIVATE);
        } else if (e.item.text === "Synchronize") {
            actionTrigger(SegmentTopRightBlockActions.SYNCHRONIZE);
        }
    }

    const statusValues = ['All', 'Available', 'In Progress', 'Error' ];

    const groups = accessibleGroups?.map((item, i) => {
        return (
            {text: item.group_name, id: item.id}
        )
    });
    const defaultGroupDisplay = {
        text: "Groups", id: ""
    }

    const users = accessibleUsers?.map((item, i) => {
        return (
            {text: item.first_name+" "+item.last_name, id: item.id}
        )
    });
    const defaultUsersDisplay = {
        text: "Users", id: ""
    }

    const handleSearch = (e) => {
        sessionStorage.setItem("searchSegmentName", searchSegmentName);
        loadItems(searchSegmentName, rightTopSelection);
    };

    const validateAppendReplaceCriteria = () => {
        const selectedSegments = profileFormik.values.selectedSegments;
        if (selectedSegments[0].subType !==
            selectedSegments[1].subType) return false;
        // const today = moment();
        // for (let i=0; i<selectedSegments.length; i++) {
        //     for (let j=0; j<selectedSegments[i].activation_queue_data.length; j++) {
        //         let queueData = selectedSegments[i].activation_queue_data[j];
        //         const futureDate = moment(queueData.campaign_range_end, 'YYYY-MM-DD');
        //         if (!today.isAfter(futureDate)) {
        //             return true;
        //         }
        //     }
        // }
        return true;
    };

    return (
        <>
            <Toolbar style={{marginLeft:"-1rem", marginRight:"-1rem", padding:"0", borderColor:"transparent"}}>
                <ToolbarItem>
                    <Tooltip anchorElement="target" position="top" style={{textAlign:"center"}}>
                        <div className="tooltip-wrapper block" title="Edit">
                            <NavLink
                                onClick={(e) => !canEnableEditSegmentButton() ? e.preventDefault() : ''}
                                to={`${EDIT_SEGMENT_ROUTE}?id=${profileFormik?.values?.selectedSegments[0]?.id}&name=${profileFormik?.values?.selectedSegments[0]?.objectName}&type=${segment1ObjectType}`}
                                title="Edit Segment"
                                style={segment1Status !== "AVAILABLE"  || !canEnableEditSegmentButton() ? {pointerEvents: "none"} : null}
                            >
                                <Button className={`btn btn-primary shadow sharp btn-xs mr-1 ${!canEnableEditSegmentButton() ? 'disabled':''}`}>
                                    <i className="fa fa-pencil" style={{color: "#FFFFFF"}}></i>
                                </Button>
                            </NavLink>
                        </div>
                    </Tooltip>
                    {/*<Tooltip anchorElement="target" position="top" style={{textAlign:"center"}}>*/}
                    {/*    <div className="tooltip-wrapper block" title="Edit">*/}
                    {/*        <NavLink*/}
                    {/*            onClick={(e) => !canEnableEditSegmentButton() ? e.preventDefault() : ''}*/}
                    {/*            to={`${EDIT_SEGMENT_ROUTE}?id=${profileFormik?.values?.selectedSegments[0]?.id}&name=${profileFormik?.values?.selectedSegments[0]?.objectName}&type=${segment1ObjectType}`}*/}
                    {/*            title="Edit Segment"*/}
                    {/*            style={segment1Status !== "AVAILABLE" || !canEnableEditSegmentButton() ? {pointerEvents: "none"} : null}*/}
                    {/*        >*/}
                    {/*            <Button*/}
                    {/*                className={`btn btn-primary shadow sharp btn-xs mr-1 ${!canEnableEditSegmentButton() ? 'disabled' : ''}`}>*/}
                    {/*                <i className="fa fa-pencil" style={{color: "#FFFFFF"}}></i>*/}
                    {/*            </Button>*/}
                    {/*        </NavLink>*/}
                    {/*    </div>*/}
                    {/*</Tooltip>*/}
                </ToolbarItem>
                {/*<ToolbarSpacer/>*/}
                {/*Delete button moved*/}
                {/*<ToolbarItem>*/}
                {/*    <Tooltip anchorElement="target" position="top" style={{textAlign:"center"}}>*/}
                {/*        <div className="tooltip-wrapper block" title="Delete">*/}
                {/*            <Button*/}
                {/*                className={`btn btn-danger shadow btn-xs sharp ${!canEnableDeleteSegmentButton() ? 'disabled':''}`}*/}
                {/*                data-bs-toggle="collapse"*/}
                {/*                aria-controls="collapseDelete"*/}
                {/*                aria-expanded="false"*/}
                {/*                title="Delete"*/}
                {/*                onClick={()=>{*/}
                {/*                    identifyDeleteMode(DELETE_MODE_MULTI);*/}
                {/*                    actionTrigger(SegmentTopRightBlockActions.DELETE)*/}
                {/*                }}*/}
                {/*            >*/}
                {/*                <i className="fa fa-trash" style={{color:"#FFFFFF"}}></i>*/}
                {/*            </Button>*/}
                {/*        </div>*/}
                {/*    </Tooltip>*/}
                {/*</ToolbarItem>*/}
                {/*<ToolbarSpacer/>*/}
                <ToolbarItem>
                    <Tooltip anchorElement="target" position="top" style={{textAlign: "center"}}>
                        <div className="tooltip-wrapper block" title="Merge">
                            <Button
                                className={`btn btn-primary shadow sharp btn-xs mr-1 ${!canEnableMergeSegmentButton() ? 'disabled' : ''}`}
                                title="Merge"
                                data-bs-toggle="collapse"
                                aria-expanded="false"
                                aria-controls="collapseMerge"
                                onClick={() => {
                                    actionTrigger(SegmentTopRightBlockActions.MERGE)
                                }}
                            >
                                <i className="fa fa-object-group"></i>
                            </Button>
                        </div>
                    </Tooltip>
                </ToolbarItem>
                {/*<ToolbarSpacer/>*/}
                <ToolbarItem>
                    <Tooltip anchorElement="target" position="top" style={{textAlign:"center"}}>
                        <div className="tooltip-wrapper block" title="Copy">
                            <Button
                                role="button"
                                className={`btn btn-primary shadow btn-xs sharp mr-1 ${!canEnableCopySegmentButton() ? 'disabled' : ''}`}
                                title="Copy"
                                data-bs-target="#CopySegment"
                                onClick={() => {
                                    profileFormik.values.segment_name = segmentName + " Copy";
                                    actionTrigger(SegmentTopRightBlockActions.COPY)
                                }}
                            >
                                <i className="fa fa-copy"></i>
                            </Button>
                        </div>
                    </Tooltip>
                </ToolbarItem>
                {/*<ToolbarSpacer/>*/}
                <ToolbarItem>
                    <Tooltip anchorElement="target" position="top" style={{width:"8rem", textAlign:"center"}}>
                        <div className="tooltip-wrapper block" title="Append/Replace">
                            <Button
                                className={`btn btn-primary shadow sharp btn-xs mr-1 ${!canEnableAppendReplaceButton() ? 'disabled' : ''}`}
                                title="Append/Replace"
                                data-bs-toggle="collapse"
                                aria-expanded="false"
                                aria-controls="collapseAppendReplace"
                                onClick={() => {
                                    actionTrigger(SegmentTopRightBlockActions.APPENDREPLACE)
                                }}
                            >
                                <i className="fas fa-retweet"></i>
                            </Button>
                        </div>
                    </Tooltip>
                </ToolbarItem>
                {/*<ToolbarSpacer/>*/}
                <ToolbarItem>
                    <Tooltip anchorElement="target" position="top" style={{textAlign:"center"}}>
                        <div className="tooltip-wrapper block" title="Split">
                            <Button
                                className={`btn btn-primary shadow btn-xs sharp mr-1 ${!canEnableSplitSegmentButton() ? 'disabled' : ''}`}
                                title="Split"
                                data-bs-toggle="collapse"
                                aria-expanded="false"
                                aria-controls="collapseSplit"
                                onClick={() => {
                                    actionTrigger(SegmentTopRightBlockActions.SPLIT)
                                }}
                            >
                                <i className="fa fa-object-ungroup"></i>
                            </Button>
                        </div>
                    </Tooltip>
                </ToolbarItem>
                {/*<ToolbarSpacer/>*/}
                <ToolbarItem>
                    <Tooltip anchorElement="target" position="top" style={{textAlign:"center"}}>
                            <div className="tooltip-wrapper block" title="Deactivate">
                                <Button icon="pause" className={`btn btn-primary shadow sharp btn-xs mr-1 ${!canEnableDeactivateButton() ? 'disabled':''}`}
                                        title="Deactivate"
                                        data-bs-toggle="collapse"
                                        aria-controls="collapseDeactivate"
                                        aria-expanded="false"
                                        onClick={()=>{actionTrigger(SegmentTopRightBlockActions.DEACTIVATE)}} />
                                        {/*onClick={() => deactivateSegments(profileFormik?.values?.selectedSegments[0]?.id)}>*/}
                                {/*</Button>*/}
                            </div>
                    </Tooltip>
                </ToolbarItem>
                {/*<ToolbarSpacer/>*/}
                <ToolbarItem>
                    <Tooltip anchorElement="target" position="top" style={{textAlign:"center"}}>
                            <div className="tooltip-wrapper block" title="Synchronize">
                                <Button
                                    className={`btn btn-primary shadow btn-xs sharp mr-1 ${!canEnableSynchronizeButton() ? 'disabled':''}`}
                                    title="Synchronize"
                                    data-bs-toggle="collapse"
                                    aria-expanded="false"
                                    aria-controls="collapseSynchronize"
                                    onClick={()=>{
                                        actionTrigger(SegmentTopRightBlockActions.SYNCHRONIZE)
                                    }}
                                >
                                    <i className="fa fa-sync" style={{color:"#FFFFFF"}}></i>
                                </Button>
                            </div>
                    </Tooltip>
                </ToolbarItem>
                {/*<ToolbarSpacer/>*/}
                <ToolbarItem>
                    <Tooltip anchorElement="target" position="top" style={{textAlign:"center"}}>
                            <div className="tooltip-wrapper block" title="Look-alike">
                                <Button
                                    className={`btn btn-primary shadow btn-xs sharp mr-1 ${!canEnableLookalikeSegmentButton() ? 'disabled':''}`}
                                    data-bs-toggle="collapse"
                                    aria-controls="collapseLookalike"
                                    aria-expanded="false"
                                    title="Look-alike"
                                    onClick={()=>{
                                        actionTrigger(SegmentTopRightBlockActions.LOOK_LIKE)
                                    }}
                                >
                                    <i className="fa fa-star-half-o"></i>
                                </Button>
                            </div>
                    </Tooltip>
                </ToolbarItem>
                <ToolbarSpacer/>
                <ToolbarSeparator className={"toolbar-separator"}/>
                <ToolbarSpacer/>
                <ToolbarItem>
                    <Tooltip anchorElement="target" position="top" style={{textAlign:"center"}}>
                        <div className="tooltip-wrapper block" title="Profile">
                            <Button icon="pdf" className={`btn btn-primary shadow btn-xs sharp mr-1 ${!canEnableProfileReportButton() ? 'disabled':''}`} title="Profile"
                                    onClick={()=>handleGetReportBtn(profileFormik?.values?.selectedSegments[0]?.id, profileFormik?.values?.selectedSegments[0]?.profileDataObsolete)}
                                    data-bs-toggle="collapse"
                                    aria-expanded="false"
                                    aria-controls="collapseGenerateReportDialogue">
                            </Button>
                        </div>
                    </Tooltip>
                </ToolbarItem>
                {/*<ToolbarSpacer/>*/}
                <ToolbarItem>
                    <Tooltip anchorElement="target" position="top" style={{textAlign:"center"}}>
                        <div className="tooltip-wrapper block" title="SpotLight">
                            <Button icon="eye"
                                    className={`btn btn-primary shadow sharp btn-xs mr-1 ${!canEnableIdentityReportButton() ? 'disabled' : ''} ${!canEnableIdentityIDXButton() ? '' : 'd-none'}`}
                                    title="Spotlight Report"
                                    onClick={() => {
                                        actionTrigger(SegmentTopRightBlockActions.IDENTITY)
                                    }}>
                            </Button>
                            <Button icon="eye"
                                    className={`btn btn-primary shadow sharp btn-xs mr-1 ${!canEnableIdentityIDXButton() ? 'disabled' : ''} ${!canEnableIdentityIDXButton() ? 'd-none' : ''}`}
                                    title="IdentityIDX"
                                    onClick={() => {
                                        actionTrigger(SegmentTopRightBlockActions.IDENTITYIDX)
                                    }}>
                            </Button>
                        </div>
                    </Tooltip>
                </ToolbarItem>
                <ToolbarSpacer/>
                {/*<ToolbarItem>*/}
                {/*    <Tooltip anchorElement="target" position="top" style={{textAlign: "center"}}>*/}
                {/*        <div className="tooltip-wrapper block" title="Merge">*/}
                {/*            <Button*/}
                {/*                className={`btn btn-primary shadow sharp btn-xs mr-1 ${!canEnableMergeSegmentButton() ? 'disabled' : ''}`}*/}
                {/*                title="Merge"*/}
                {/*                data-bs-toggle="collapse"*/}
                {/*                aria-expanded="false"*/}
                {/*                aria-controls="collapseMerge"*/}
                {/*                onClick={() => {*/}
                {/*                    actionTrigger(SegmentTopRightBlockActions.MERGE)*/}
                {/*                }}*/}
                {/*            >*/}
                {/*                <i className="fa fa-object-group"></i>*/}
                {/*            </Button>*/}
                {/*        </div>*/}
                {/*    </Tooltip>*/}
                {/*</ToolbarItem>*/}
                {/*<ToolbarSpacer/>*/}
                {/*<ToolbarItem>*/}
                {/*    <Tooltip anchorElement="target" position="top" style={{textAlign:"center"}}>*/}
                {/*        <div className="tooltip-wrapper block" title="Copy">*/}
                {/*            <Button*/}
                {/*                role="button"*/}
                {/*                className={`btn btn-primary shadow btn-xs sharp mr-1 ${!canEnableCopySegmentButton() ? 'disabled' : ''}`}*/}
                {/*                title="Copy"*/}
                {/*                data-bs-target="#CopySegment"*/}
                {/*                onClick={() => {*/}
                {/*                    profileFormik.values.segment_name = segmentName + " Copy";*/}
                {/*                    actionTrigger(SegmentTopRightBlockActions.COPY)*/}
                {/*                }}*/}
                {/*            >*/}
                {/*                <i className="fa fa-copy"></i>*/}
                {/*            </Button>*/}
                {/*        </div>*/}
                {/*    </Tooltip>*/}
                {/*</ToolbarItem>*/}
                {/*<ToolbarSpacer/>*/}
                {/*<ToolbarItem>*/}
                {/*    <Tooltip anchorElement="target" position="top" style={{width:"8rem", textAlign:"center"}}>*/}
                {/*        <div className="tooltip-wrapper block" title="Append/Replace">*/}
                {/*            <Button*/}
                {/*                className={`btn btn-primary shadow sharp btn-xs mr-1 ${!canEnableAppendReplaceButton() ? 'disabled' : ''}`}*/}
                {/*                title="Append/Replace"*/}
                {/*                data-bs-toggle="collapse"*/}
                {/*                aria-expanded="false"*/}
                {/*                aria-controls="collapseAppendReplace"*/}
                {/*                onClick={() => {*/}
                {/*                    actionTrigger(SegmentTopRightBlockActions.APPENDREPLACE)*/}
                {/*                }}*/}
                {/*            >*/}
                {/*                <i className="fas fa-retweet"></i>*/}
                {/*            </Button>*/}
                {/*        </div>*/}
                {/*    </Tooltip>*/}
                {/*</ToolbarItem>*/}
                {/*<ToolbarSpacer/>*/}
                {/*<ToolbarItem>*/}
                {/*    <Tooltip anchorElement="target" position="top" style={{textAlign:"center"}}>*/}
                {/*        <div className="tooltip-wrapper block" title="Split">*/}
                {/*            <Button*/}
                {/*                className={`btn btn-primary shadow btn-xs sharp mr-1 ${!canEnableSplitSegmentButton() ? 'disabled' : ''}`}*/}
                {/*                title="Split"*/}
                {/*                data-bs-toggle="collapse"*/}
                {/*                aria-expanded="false"*/}
                {/*                aria-controls="collapseSplit"*/}
                {/*                onClick={() => {*/}
                {/*                    actionTrigger(SegmentTopRightBlockActions.SPLIT)*/}
                {/*                }}*/}
                {/*            >*/}
                {/*                <i className="fa fa-object-ungroup"></i>*/}
                {/*            </Button>*/}
                {/*        </div>*/}
                {/*    </Tooltip>*/}
                {/*</ToolbarItem>*/}
                {/*<ToolbarSpacer/>*/}
                {/*<ToolbarItem>*/}
                {/*            <div className="tooltip-wrapper block" title="Look-alike">*/}
                {/*                <Button*/}
                {/*                    className={`btn btn-primary shadow btn-xs sharp mr-1 ${!canEnableLookalikeSegmentButton() ? 'disabled':''}`}*/}
                {/*                    data-bs-toggle="collapse"*/}
                {/*                    aria-controls="collapseLookalike"*/}
                {/*                    aria-expanded="false"*/}
                {/*                    title="Look-alike"*/}
                {/*                    onClick={()=>{*/}
                {/*                        actionTrigger(SegmentTopRightBlockActions.LOOK_LIKE)*/}
                {/*                    }}*/}
                {/*                >*/}
                {/*                    <i className="fa fa-star-half-o"></i>*/}
                {/*                </Button>*/}
                {/*            </div>*/}
                {/*</ToolbarItem>*/}
                {/*<ToolbarSpacer/>*/}
                {/*<ToolbarItem>*/}
                {/*            <div className="tooltip-wrapper block" title="Deactivate">*/}
                {/*                <Button icon="pause" className={`btn btn-primary shadow sharp btn-xs mr-1 ${!canEnableDeactivateButton() ? 'disabled':''}`}*/}
                {/*                        title="Deactivate"*/}
                {/*                        data-bs-toggle="collapse"*/}
                {/*                        aria-controls="collapseDeactivate"*/}
                {/*                        aria-expanded="false"*/}
                {/*                        onClick={()=>{actionTrigger(SegmentTopRightBlockActions.DEACTIVATE)}} />*/}
                {/*                        /!*onClick={() => deactivateSegments(profileFormik?.values?.selectedSegments[0]?.id)}>*!/*/}
                {/*                /!*</Button>*!/*/}
                {/*            </div>*/}
                {/*</ToolbarItem>*/}
                {/*<ToolbarSpacer/>*/}
                {/*<ToolbarItem>*/}
                {/*            <div className="tooltip-wrapper block" title="Synchronize">*/}
                {/*                <Button*/}
                {/*                    className={`btn btn-primary shadow btn-xs sharp mr-1 ${!canEnableSynchronizeButton() ? 'disabled':''}`}*/}
                {/*                    title="Synchronize"*/}
                {/*                    data-bs-toggle="collapse"*/}
                {/*                    aria-expanded="false"*/}
                {/*                    aria-controls="collapseSynchronize"*/}
                {/*                    onClick={()=>{*/}
                {/*                        actionTrigger(SegmentTopRightBlockActions.SYNCHRONIZE)*/}
                {/*                    }}*/}
                {/*                >*/}
                {/*                    <i className="fa fa-sync" style={{color:"#FFFFFF"}}></i>*/}
                {/*                </Button>*/}
                {/*            </div>*/}
                {/*</ToolbarItem>*/}
                {/*<ToolbarSpacer/>*/}
                {/*<ToolbarItem>*/}
                {/*    <Tooltip anchorElement="target" position="top" style={{textAlign:"center"}}>*/}
                {/*        <div className="tooltip-wrapper block" title="More Options">*/}
                {/*            <DropDownButton*/}
                {/*                className={`btn btn-primary shadow btn-xs sharp mr-1 more-options-button ${!canEnableMoreOptionsButton() ? 'disabled' : ''}`}*/}
                {/*                iconClass="fas fa-bars fa-fw"*/}
                {/*                items={moreOptions}*/}
                {/*                popupSettings={{popupClass: "k-toolbar-popup"}}*/}
                {/*                onItemClick={(e) => {*/}
                {/*                    moreOptionsHandler(e)*/}
                {/*                }}>*/}
                {/*            </DropDownButton>*/}
                {/*        </div>*/}
                {/*    </Tooltip>*/}
                {/*</ToolbarItem>*/}
            </Toolbar>
        </>
    )
}

export default TreeSegmentDashboardToolBar
