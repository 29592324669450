import React, {useState} from 'react'
import "./TreeSegmentDashboardToolBar.css";
import {SegmentTopRightBlockActions} from "../../../helpers/SegmentationHelpers";
import {
    Button,
    Toolbar,
    ToolbarItem
} from "@progress/kendo-react-buttons";
import { Tooltip } from "@progress/kendo-react-tooltip";
import {
    DELETE_MODE_MULTI, SUCCESS_STATUS
} from "../../../api/constants";

import {semcastAxios, showErrorToastMessage, showSuccessToastMessage} from "../../../Utils/Common";

const TreeSegmentDashboardDeleteButton = ({
                                        actionSubscriberOfTopRightBlock,
                                        profileFormik,
                                        identifyDeleteMode
                                    // onChangeStatus
                                }) => {

    const activationTypes = ["Activation","Impression-Activation"];
    let selectedSegmentsLength = profileFormik?.values?.selectedSegments?.length;

    const canEnableDeleteSegmentButton = ()=>{
        //Condition:
        // 1. selected segments status have to be AVAILABLE/COMPLETED/ACTIVE/ERROR
        // 2. selected segments objectType cannot be activation/impression-activation (for proper billing).
        let status = [];
        let invalidTypes = [];

        profileFormik.values.selectedSegments.forEach(function (v) {
            if(v.status!== 'AVAILABLE' && v.status!== 'COMPLETED' && v.status!== 'ACTIVE' && v.status!== 'ERROR')   status.push(v.status);
            if(activationTypes.includes(v.objectType)) invalidTypes.push(v.objectType);
        });
        try {
            return selectedSegmentsLength > 0 && status.length === 0 && invalidTypes.length === 0;
        } catch (e) {
            return false
        }
    }

    const  actionSubscriberOfTopIconRow = (action: SegmentTopRightBlockActions)=> {
        actionSubscriberOfTopRightBlock(action)
    };
    const actionTrigger = (action:SegmentTopRightBlockActions) =>{
        actionSubscriberOfTopIconRow(action)
    };

    return (
        <>

            <Toolbar style={{marginRight:"-1rem", padding:"0", borderColor:"transparent"}}>
                <ToolbarItem>
                    <Tooltip anchorElement="target" position="top" style={{textAlign:"center"}}>
                        <div className="tooltip-wrapper block" title="Delete">
                            <Button
                                className={`btn btn-danger shadow btn-xs sharp ${!canEnableDeleteSegmentButton() ? 'disabled':''}`}
                                data-bs-toggle="collapse"
                                aria-controls="collapseDelete"
                                aria-expanded="false"
                                title="Delete"
                                onClick={()=>{
                                    identifyDeleteMode(DELETE_MODE_MULTI);
                                    actionTrigger(SegmentTopRightBlockActions.DELETE)
                                }}
                            >
                                <i className="fa fa-trash" style={{color:"#FFFFFF"}}></i>
                            </Button>
                        </div>
                    </Tooltip>
                </ToolbarItem>
            </Toolbar>

        </>
    )
}

export default TreeSegmentDashboardDeleteButton
