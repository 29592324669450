import acxiomLogo from "../Admin/Components/Segmentation/temporaryresources/images/acxiom.svg";
import analyticsIQLogo from "../Admin/Components/Segmentation/temporaryresources/images/analyticsIQ.png";
import advancedContextualLogo from "../Admin/Components/Segmentation/temporaryresources/images/Advanced Contextual logo vertical.png";
import americanFlag from "../Admin/Components/Segmentation/temporaryresources/images/american-flag.png";
import healthcareSpecialistLogo from "../Admin/Components/Segmentation/temporaryresources/images/stethoscope.png";
// FD:10086
// import healthWiseLogo from "../Admin/Components/Segmentation/temporaryresources/images/Healthwise Data logo-no background.svg";
import house from "../Admin/Components/Segmentation/temporaryresources/images/house.png";
// FD:10912
// import i360Logo from "../Admin/Components/Segmentation/temporaryresources/images/i360.svg";
// import iqviaLogo from "../Admin/Components/Segmentation/temporaryresources/images/iqvia.svg";
import knowWhoLogo from "../Admin/Components/Segmentation/temporaryresources/images/KnowWho.svg";
import l2DataLogo from "../Admin/Components/Segmentation/temporaryresources/images/l2data.svg";
import datatrustLogo from "../Admin/Components/Segmentation/temporaryresources/images/datatrust.png";
import smllearningLogo from "../Admin/Components/Segmentation/temporaryresources/images/college-logo.png";
import piggyBank from "../Admin/Components/Segmentation/temporaryresources/images/piggy-bank.png";
import purpleLabLogo from "../Admin/Components/Segmentation/temporaryresources/images/purplelab.svg";
import salesIntelLogo from "../Admin/Components/Segmentation/temporaryresources/images/salesintel.svg";
import shoppingCart from "../Admin/Components/Segmentation/temporaryresources/images/shopping-cart.png";
import specialty from "../Admin/Components/Segmentation/temporaryresources/images/specialty.png";
import sportsHockey from "../Admin/Components/Segmentation/temporaryresources/images/sports-hockey.png";
import titleMatching from "../Admin/Components/Segmentation/temporaryresources/images/titleBased.jpg";
import travelLogo from "../Admin/Components/Segmentation/temporaryresources/images/plane.png";
//import qwarryLogo from "../Admin/Components/Segmentation/temporaryresources/images/Qwarry.png";

import analyticsIQDatasheet from "../Admin/Components/Segmentation/temporaryresources/datasheets/AnalyticsIQ-PeopleCore-Overview.pdf";
import purpleLabDatasheet from "../Admin/Components/Segmentation/temporaryresources/datasheets/purplelab_audiences_dtc_semcast_1_.pdf";
import salesIntelDatasheet from "../Admin/Components/Segmentation/temporaryresources/datasheets/SalesIntel_2023_V2.pdf";
import salesIntelTechnoDatasheet from "../Admin/Components/Segmentation/temporaryresources/datasheets/Sales_Intel_Technographics_2023.pdf";


import React from "react";

export default {
  coreDemographic: {
    groupType: "coreDemographic",
    groupName: "Core Demographic",
    title: "Core Demographic",
    dialogTitle: "Core Demographic Segments",
    targetType: "CONSUMER",
    displayType: "Core Demographic",
    displayOrder: 1,
    consumerVisible: "none",
    icon: "",
    iconWidth:"80px",
    price: "$",
    endPoint: "",
    groupExplanation: "Placeholder for coreDemographic data entries",
    dataOwner: "semcasting",
  },
  coreFirmographic: {
    groupType: "coreFirmographic",
    groupName: "Core Firmographic",
    title: "Core Firmographic",
    dialogTitle: "Core Firmographic Segments",
    targetType: "BUSINESS",
    displayType: "Core Firmographic",
    displayOrder: 1,
    consumerVisible: "none",
    icon: "",
    iconWidth:"80px",
    price: "$",
    endPoint: "",
    groupExplanation: "Placeholder for coreFirmographic data entries",
    dataOwner: "semcasting",
  },
  consumer: {
    groupType: "consumer",
    groupName: "Retail",
    title: "Retail",
    dialogTitle: "Retail Segments",
    targetType: "CONSUMER",
    displayType: "Retailers",
    displayOrder: 2,
    consumerVisible: "consumer-visible",
    icon: shoppingCart,
    iconWidth: "90px",
    price: "$1.00",
    endPoint:
        "ppk-segments?segment_group=Consumer&orderby=segment_category,segment_name",
    groupExplanation: "Retail Lorem ipsum dolar sit amet",
    dataOwner: "semcasting",
  },
  politicalAffiliations: {
    groupType: "politicalAffiliations",
    groupName: "Politics",
    title: "Politics",
    dialogTitle: "Political Affiliations",
    targetType: "CONSUMER",
    displayType: "Politics",
    displayOrder: 8,
    consumerVisible: "consumer-visible",
    icon: americanFlag,
    iconWidth:"50px",
    price: "$1.00",
    endPoint: "ppk-segments?segment_group=Political Affiliations",
    groupExplanation:
        "Political Affiliations Networking Lorem ipsum dolar sit amet",
    dataOwner: "semcasting",
  },
  sportsAttendees: {
    groupType: "sportsAttendees",
    groupName: "Sports Attendees",
    title: "Sports Attendees",
    dialogTitle: "Sports Attendees",
    targetType: "CONSUMER",
    displayType: "Travel/Entertainment",
    displayOrder: 6,
    consumerVisible: "consumer-visible",
    icon: sportsHockey,
    iconWidth:"70px",
    price: "$1.00",
    endPoint:
        "ppk-segments?segment_group=Sports Attendees&orderby=segment_name",
    groupExplanation: "Sports Attendees Lorem ipsum dolar sit amet",
    dataOwner: "semcasting",
  },

  homeOwner: {
    groupType: "homeOwner",
    groupName: "Home Ownership",
    title: "Home Ownership",
    dialogTitle: "Home Ownership Segments",
    targetType: "CONSUMER",
    displayType: "Home Services",
    displayOrder: 5,
    consumerVisible: "consumer-visible",
    icon: house,
    iconWidth:"90px",
    price: "$1.00",
    endPoint: "ppk-segments?segment_group=Home Owner&orderby=segment_name",
    groupExplanation: "Home Ownership Lorem ipsum dolar sit amet",
    dataOwner: "semcasting",
  },

  personalFinance: {
    groupType: "personalFinance",
    groupName: "Personal Finance",
    title: "Personal Finance",
    dialogTitle: "Personal Finance Segments",
    targetType: "CONSUMER",
    displayType: "Demographic",
    displayOrder: 1,
    consumerVisible: "consumer-visible",
    icon: piggyBank,
    iconWidth:"80px",
    price: "$1.00",
    endPoint: "ppk-segments?segment_group=Personal Finance&orderby=segment_category,description",
    groupExplanation: "Personal Finance Lorem ipsum dolar sit amet",
    dataOwner: "semcasting",
  },
  netWorth: {
    groupType: "netWorth",
    groupName: "Net Worth",
    consumerVisible: "consumer-visible",
    endPoint: "ppk-segments?segment_category=Net Worth&orderby=segment_name",
  },
  discretionaryIncome: {
    groupType: "discretionaryIncome",
    groupName: "Discretionary Income",
    consumerVisible: "consumer-visible",
    endPoint: "ppk-segments?segment_category=Discretionary Income",
  },
  titleMatching: {
    groupType: "titleMatching",
    groupName: "Title Based Targeting",
    title: "Title Based Targeting",
    dialogTitle: "Title Based Targeting",
    targetType: "BOTH",
    displayType: "B2B",
    displayOrder: 4,
    consumerVisible: "none",
    icon: titleMatching,
    iconWidth:"78px",
    price: "$1.50",
    endPoint:
        "ppk-segments?segment_group=Title Matching&orderby=segment_category,segment_name",
    groupExplanation: "TitleMatching",
    dataOwner: "semcasting",
  },
  // businessTitleMatching: {
  //   groupType: "businessTitleMatching",
  //   groupName: "Business Title Based Targeting",
  //   title: "Title Based Targeting",
  //   targetType: "BUSINESS",
  //   displayType: "advanced",
  //   consumerVisible: "none",
  //   icon: "titleBasedTargeting",
  //   price: "$1.50",
  //   endPoint:
  //       "ppk-segments?segment_group=Title Matching&orderby=segment_category,segment_name",
  //   groupExplanation: "TitleMatching",
  //   dataOwner: "semcasting",
  // },
  specialtySegments: {
    groupType: "specialtySegments",
    groupName: "Specialty Segments",
    title: "Specialty Segments",
    dialogTitle: "Specialty Segments",
    targetType: "CONSUMER",
    displayType: "Demographic",
    displayOrder: 1,
    consumerVisible: "consumer-visible",
    icon: specialty,
    iconWidth:"58px",
    price: "$1.50",
    endPoint:
        "ppk-segments?segment_group=Specialty Segments&orderby=segment_category,segment_name",
    groupExplanation: "SpecialtySegments",
    dataOwner: "semcasting",
  },
  specialtySegmentsBusiness: {
    groupType: "specialtySegmentsBusiness",
    groupName: "Specialty Segments Business",
    title: "Specialty Segments",
    dialogTitle: "Specialty Segments",
    targetType: "BUSINESS",
    displayType: "Firmographic",
    displayOrder: 1,
    consumerVisible: "none",
    icon: specialty,
    iconWidth:"58px",
    price: "$1.50",
    endPoint:
        "ppk-segments?segment_group=Business Specialty Segments&orderby=segment_category,segment_name",
    groupExplanation: "BusinessSpecialtySegments",
    dataOwner: "semcasting",
  },
  hcpspecialtySegmentsBusiness:{
    groupType : 'hcpspecialtySegmentsBusiness',
    groupName : 'HealthCare Specialist',
    title: "HealthCare Specialist",
    dialogTitle: "HealthCare Specialist",
    targetType: "BUSINESS",
    displayType: "Healthcare",
    displayOrder: 4,
    consumerVisible : 'none',
    icon: healthcareSpecialistLogo,
    iconWidth:"70px",
    price:'$2.00',
    endPoint : 'ppk-segments?segment_group=HCP_SPECIALTY&orderby=segment_category,segment_name',
    groupExplanation : 'HealthCare Provider Specialty Segments',
    dataOwner:'semcasting'
  },
  // iqvia:{
  //   groupType : 'iqvia',
  //   groupName : 'IQVIA',
  //   title: "IQVIA",
  //   dialogTitle: "IQVIA HCP, Full Care Teams:",
  //   targetType: "BOTH",
  //   displayType: "Healthcare",
  //   displayOrder: 3,
  //   icon:iqviaLogo,
  //   iconWidth:"90px",
  //   price:'$25.00',
  //   consumerVisible : 'none',
  //   endPoint : 'ppk-segments?segment_group=Iqvia&orderby=segment_category,segment_name',
  //   groupExplanation : 'Iqvia Segments',
  //   dataOwner:'iqvia'
  // },
  travelAndEntertainment: {
    groupType: "travelAndEntertainment",
    groupName: "Travel and Entertainment",
    title: "Travel-Entertainment",
    dialogTitle: "Travel / Entertainment Segments",
    targetType: "BUSINESS",
    displayType: "Travel/Entertainment",
    displayOrder: 6,
    consumerVisible: "none",
    // icon: "travelAndEntertainment",
    // icon: <i className="fas fa-plane-departure"></i>,
    icon: travelLogo,
    iconWidth:"130px",
    price: "$1.50",
    endPoint:
    // "ppk-segments?segment_group=Travel and Entertainment&orderby=segment_category,segment_name",
        "ppk-segments?segment_group=Travel%20and%20Entertainment%20Segments&orderby=segment_category,segment_name",
    groupExplanation: "TravelAndEntertainment",
    dataOwner: "semcasting",
  },
  spectrumMedia: {
    groupType: "spectrumMedia",
    groupName: "Advanced Contextual",
    title: "Advanced Contextual",
    dialogTitle: "Advanced Contextual Media Segments",
    targetType: "CONSUMER",
    displayType: "Contextual",
    displayOrder: 7,
    consumerVisible: "consumer-visible",
    icon: advancedContextualLogo,
    iconWidth:"90px",
    price: "$1.25",
    endPoint:
        "ppk-segments?segment_group=Spectrum Media&orderby=segment_category,segment_name",
    groupExplanation: "Advanced Contextual",
    dataOwner: "spectrumMedia",
  },
  // qwarry: {
  //   groupType: "qwarry",
  //   groupName: "Qwarry",
  //   title: "Qwarry",
  //   dialogTitle: "Qwarry Segments",
  //   targetType: "CONSUMER",
  //   displayType: "Contextual",
  //   displayOrder: 7,
  //   consumerVisible: "consumer-visible",
  //   icon: qwarryLogo,
  //   iconWidth:"150px",
  //   price: "$1.50",
  //   endPoint:
  //       "ppk-segments?segment_group=QWARRY&orderby=segment_category,segment_name",
  //   groupExplanation: "Qwarry",
  //   dataOwner: "qwarry",
  // },
  //FD#12297
  // qwarry: {
  //   groupType: "qwarry",
  //   groupName: "Qwarry",
  //   title: "Qwarry",
  //   dialogTitle: "Qwarry Segments",
  //   targetType: "CONSUMER",
  //   displayType: "Contextual",
  //   displayOrder: 7,
  //   consumerVisible: "consumer-visible",
  //   icon: qwarryLogo,
  //   iconWidth:"150px",
  //   price: "$1.50",
  //   endPoint:
  //       "ppk-segments?segment_group=QWARRY&orderby=segment_category,segment_name",
  //   groupExplanation: "Qwarry",
  //   dataOwner: "qwarry",
  // },
  acxiomLifestyle: {
    groupType: "acxiomLifestyle",
    groupName: "Acxiom LifeStyle",
    title: "Acxiom LifeStyle",
    dialogTitle: "Acxiom LifeStyle Segments",
    targetType: "CONSUMER",
    displayType: "Demographic",
    displayOrder: 1,
    consumerVisible: "consumer-visible",
    // icon: "acxiomLifestyle",
    icon: acxiomLogo,
    iconWidth:"90px",
    price: "$1.25",
    endPoint:
    // "ppk-segments?segment_group=Acxiom LifeStyle&orderby=segment_category,segment_name",
        "ppk-segments?segment_group=Acxiom%20LifeStyle&orderby=segment_category,segment_name",
    groupExplanation: "Acxiom LifeStyle",
    dataOwner: "acxiomLifestyle",
  },
  analyticsIQ: {
    groupType: "analyticsIQ",
    groupName: "Analytics IQ",
    title: "Analytics IQ",
    dialogTitle: "Analytics IQ Segments",
    targetType: "CONSUMER",
    displayType: "Demographic",
    displayOrder: 4,
    consumerVisible: "consumer-visible",
    // icon: "acxiomLifestyle",
    icon: analyticsIQLogo,
    iconWidth:"90px",
    dataSheet: analyticsIQDatasheet,
    price: "$2.50",
    endPoint:
    // "ppk-segments?segment_group=Acxiom LifeStyle&orderby=segment_category,segment_name",
        "ppk-segments?segment_group=AnalyticsIQ&orderby=segment_category,segment_name",
    groupExplanation: "Analytics IQ",
    dataOwner: "analyticsIQ",
  },

  /*
    affinitySolutions: {
      groupType: "affinitySolutions",
      groupName: "Affinity Solutions",
      consumerVisible: "consumer-visible",
      icon: '<img src="/images/affinity_solutions.svg" alt="" class="third-party-targeting-fill targeting-icon" style="height: fit-content;"/>',
      price: "$1.25",
      endPoint:
        "ppk-segments?segment_group=Affinity Solutions&orderby=segment_category,segment_name",
      groupExplanation: "Affinity Solutions",
      dataOwner: "affinitySolutions",
    },
  */

  // FD:10086
  // healthWise: {
  //   groupType: "healthWise",
  //   groupName: "HealthWise Data",
  //   title: "HealthWise Data",
  //   dialogTitle: "HealthWise Segments",
  //   targetType: "CONSUMER",
  //   displayType: "Healthcare",
  //   displayOrder: 3,
  //   consumerVisible: "consumer-visible",
  //   icon: healthWiseLogo,
  //   iconWidth:"90px",
  //   price: "$1.75",
  //   endPoint:
  //     "ppk-segments?segment_group=HealthWise&orderby=segment_category,segment_name",
  //   groupExplanation: "HealthWise Data",
  //   dataOwner: "healthWise",
  // },

  knowWhoConsumer: {
    groupType: "knowWhoConsumer",
    groupName: "KnowWho Consumer",
    title: "KnowWho Consumer",
    dialogTitle: "KnowWho Consumer Segments",
    targetType: "CONSUMER",
    displayType: "Politics",
    displayOrder: 8,
    consumerVisible: "consumer-visible",
    icon: knowWhoLogo,
    iconWidth:"90px",
    price: "$9.00-$17.00",
    endPoint:
        "ppk-segments?segment_group=KnowWhoConsumer&orderby=segment_category,segment_name",
    groupExplanation: "KnowWhoConsumer",
    dataOwner: "knowWhoConsumer",
  },

  /*
    dataStream: {
      groupType: "dataStream",
      groupName: "DataStream",
      consumerVisible: "consumer-visible",
      icon: '<img src="/images/datastream.svg" alt="" class="third-party-targeting-fill targeting-icon" width="529.28" height="69.12"/>',
      price: "$1.50",
      endPoint:
        "ppk-segments?segment_group=DataStream&orderby=segment_category,segment_name",
      groupExplanation: "DataStream",
      dataOwner: "dataStream",
    },

    killiConsumer: {
      groupType: "killiConsumer",
      groupName: "Killi",
      consumerVisible: "consumer-visible",
      icon: '<img src="/images/killi.svg" alt="" class="third-party-targeting-fill targeting-icon" style="height: auto"/>',
      price: "$1.00",
      endPoint:
        "ppk-segments?segment_group=KilliConsumer&orderby=segment_category,segment_name",
      groupExplanation: "KilliConsumer",
      dataOwner: "killiConsumer",
    },
  */

  purpleLabHCP: {
    groupType: "purpleLabHCP",
    groupName: "PurpleLabHCP",
    title: "PurpleLabHCP",
    dialogTitle: "PurpleLab HealthCare Providers Treating People For:",
    targetType: "BOTH",
    displayType: "Healthcare",
    displayOrder: 3,
    consumerVisible: "none",
    icon: purpleLabLogo,
    iconWidth:"90px",
    dataSheet: purpleLabDatasheet,
    price: "$2.00",
    endPoint:
        "ppk-segments?segment_group=PurpleLabHCP&orderby=segment_category,segment_name",
    groupExplanation: 'PurpleLab HCPs treating people with:',
    dataOwner: "purpleLab",
  },

  purpleLabHPD: {
    groupType: "purpleLabHPD",
    groupName: "PurpleLabHPD",
    title: "PurpleLabHPD",
    dialogTitle: "PurpleLab Health Plans:",
    targetType: "CONSUMER",
    displayType: "Healthcare",
    displayOrder: 3,
    consumerVisible: "consumer-visible",
    icon: purpleLabLogo,
    dataSheet: purpleLabDatasheet,
    iconWidth:"90px",
    price: "$2.00",
    endPoint:
        "ppk-segments?segment_group=Purple_Health_Plan_Data&orderby=segment_category,segment_name",
    groupExplanation: "PurpleLabHPD",
    dataOwner: "purpleLab",
  },

  l2Data: {
    groupType: "l2Data",
    groupName: "L2-Data",
    dialogTitle: "L2-Data Segments",
    title: "L2-Data",
    targetType: "CONSUMER",
    displayType: "Politics",
    displayOrder: 9,
    consumerVisible: "consumer-visible",
    icon: l2DataLogo,
    iconWidth:"90px",
    price: "$1.50",
    endPoint:
        "ppk-segments?segment_group=L2Data&orderby=segment_category,segment_name",
    groupExplanation: "L2Data",
    dataOwner: "l2Data",
  },

  datatrust: {
    groupType: "datatrust",
    groupName: "DataTrust",
    dialogTitle: "DataTrust Segments",
    title: "DataTrust",
    targetType: "CONSUMER",
    displayType: "Politics",
    displayOrder: 8,
    consumerVisible: "consumer-visible",
    icon: datatrustLogo,
    iconWidth:"100px",
    price: "$1.50",
    endPoint:
        "ppk-segments?segment_group=DATATRUST&orderby=segment_category,segment_name",
    groupExplanation: "DataTrust",
    dataOwner: "datatrust",
  },
  // FD#11981
  smllearning: {
    groupType: "smllearning",
    groupName: "SMLLearning",
    dialogTitle: "Advertising Solutions by Sallie",
    title: "SLM Education Services",
    targetType: "CONSUMER",
    displayType: "Demographic",
    displayOrder: 4,
    consumerVisible: "consumer-visible",
    icon: smllearningLogo,
    iconWidth:"100px",
    price: "$1.75-$2.50",
    endPoint:
        "ppk-segments?segment_group=SLM Ed Services&orderby=segment_category,segment_name",
    groupExplanation: "SMLLearning",
    dataOwner: "smllearning",
  },
  // FD:10912
  // i360: {
  //   groupType: "i360",
  //   groupName: "i360",
  //   title: "i360",
  //   dialogTitle: "i360 Segments",
  //   targetType: "CONSUMER",
  //   displayType: "Politics",
  //   displayOrder: 8,
  //   consumerVisible: "consumer-visible",
  //   icon: i360Logo,
  //   iconWidth:"90px",
  //   price: "$1.50",
  //   endPoint:
  //       "ppk-segments?segment_group=i360&orderby=segment_category,segment_name",
  //   groupExplanation: "i360",
  //   dataOwner: "i360",
  // },

  // throtle: {
  //   groupType: "throtle",
  //   groupName: "ADLoop Segments",
  //   title: "ADLoop Segments",
  //   dialogTitle: "Adloop Segments",
  //   targetType: "CONSUMER",
  //   displayType: "Healthcare",
  //   displayOrder: 3,
  //   consumerVisible: "consumer-visible",
  //   icon: "throtleSegments",
  //   price: "$1.50",
  //   endPoint:
  //       "ppk-segments?segment_group=Throtle%20Segments&orderby=segment_category,segment_name",
  //   groupExplanation: "throtle",
  //   dataOwner: "throtle",
  // },
  purpleLab: {
    groupType: "purpleLab",
    groupName: "PurpleLab",
    title: "PurpleLab",
    dialogTitle: "Medications & Treatment Segments",
    targetType: "CONSUMER",
    displayType: "Healthcare",
    displayOrder: 3,
    consumerVisible: "consumer-visible",
    icon: purpleLabLogo,
    iconWidth:"90px",
    dataSheet: purpleLabDatasheet,
    price: "$2.00",
    endPoint:
        "ppk-segments?segment_group=PurpleLab&orderby=segment_category,segment_name",
    groupExplanation: "PurpleLab",
    dataOwner: "purpleLab",
  },
  salesintel:{
    groupType : 'salesintel',
    groupName : 'SalesIntel',
    title: "SalesIntel",
    dialogTitle: "SalesIntel Department Segments",
    targetType: "CONSUMER",
    displayType: "B2B",
    displayOrder: 5,
    icon:salesIntelLogo,
    iconWidth:"110px",
    dataSheet: salesIntelDatasheet,
    price:'$2.50',
    consumerVisible : "consumer-visible",
    endPoint : 'ppk-segments?segment_group=SalesIntel&orderby=segment_category,segment_name',
    groupExplanation : 'Sales Intel Department Segments',
    dataOwner:'salesintel'
  },
  salesinteltechno:{
    groupType : 'salesinteltechno',
    groupName : 'SalesIntel Techno',
    title: "SalesIntel Techno",
    dialogTitle: "SalesIntel Technology Segments",
    targetType: "CONSUMER",
    displayType: "B2B",
    displayOrder: 5,
    icon:salesIntelLogo,
    iconWidth:"110px",
    dataSheet: salesIntelTechnoDatasheet,
    price:'$2.50',
    consumerVisible : "consumer-visible",
    endPoint : 'ppk-segments?segment_group=SalesIntelTechno&orderby=segment_category,segment_name',
    groupExplanation : 'Sales Intel Technology Segments',
    dataOwner:'salesinteltechno'
  },
};
