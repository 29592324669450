import React, {useEffect, useState} from "react";
import {Card, CardBody, CardTitle} from "@progress/kendo-react-layout";
import {Label} from "@progress/kendo-react-labels";
import {Form, FormElement, FieldWrapper} from "@progress/kendo-react-form";
import "./UploadedRecords.css";

const UploadedRecords = (props) => {

    const [rowHeight, setRowHeight] = React.useState(90);
    const [colWidth, setColWidth] = React.useState(180);

    useEffect(() => {

    }, []);

    return (
        <div className={"uploaded-records-outer-div"}>
            <Card className={"uploaded-records-card"}>
                <CardTitle>
                    <Label className="section-label">SEGMENT SUMMARY</Label>
                </CardTitle>
                <CardBody>
                    {props.targetType === "CONSUMER" && (
                        <>
                            <div className={"container"}>
                                {props.summaryState.total_households >0 && <div className={"column-1"}>
                                    <div><Label className={"field-label"}>Household Match</Label></div>
                                    <div><Label
                                        className={"labels"}><b>{props.summaryState.total_households.toLocaleString("en-US")}</b></Label>
                                    </div>
                                </div>}
                                {props.summaryState.total_people > 0 && <div className={"column-2"}>
                                    <div><Label className={"field-label"}>People</Label></div>
                                    <div><Label
                                        className={"labels"}><b>{props.summaryState.total_people.toLocaleString("en-US")}</b></Label>
                                    </div>
                                </div>}
                                {props.summaryState.total_unique_emails > 0 && <div className={"column-3"}>
                                    <div><Label className={"field-label"}>Unique Emails</Label></div>
                                    <div><Label
                                        className={"labels"}><b>{props.summaryState.total_unique_emails.toLocaleString("en-US")}</b></Label>
                                    </div>
                                </div>}
                                {props.summaryState.total_phones > 0 && <div className={"column-4"}>
                                    <div><Label className={"field-label"}>Unique Phones</Label></div>
                                    <div><Label
                                        className={"labels"}><b>{props.summaryState.total_phones.toLocaleString("en-US")}</b></Label>
                                    </div>
                                </div>}
                                {props.summaryState.total_household_network_ip_addresses > 0 && <div className={"column-5"}>
                                    <div><Label className={"field-label"}>Network IP Ranges </Label></div>
                                    <div><Label
                                        className={"labels"}><b>{props.summaryState.total_household_network_ip_addresses.toLocaleString("en-US")}</b></Label>
                                    </div>
                                </div>}

                            </div>
                            <div className={"container"} style={{paddingTop: "1rem"}}>
                                {props.summaryState.total_household_wifi_ip_addresses > 0 && <div className={"column-1"}>
                                    <div><Label className={"field-label"}>Wifi IP Ranges</Label></div>
                                    <div><Label
                                        className={"labels"}><b>{props.summaryState.total_household_wifi_ip_addresses.toLocaleString("en-US")}</b></Label>
                                    </div>
                                </div>}
                                {props.summaryState.total_household_devices > 0 && <div className={"column-2"}>
                                    <div><Label className={"field-label"}>Devices</Label></div>
                                    <div><Label
                                        className={"labels"}><b>{props.summaryState.total_household_devices.toLocaleString("en-US")}</b></Label>
                                    </div>
                                </div>}
                                {props.summaryState.total_top_household_devices > 0 && <div className={"column-3"}>
                                    <div><Label className={"field-label"}>Top Devices</Label></div>
                                    <div><Label
                                        className={"labels"}><b>{props.summaryState.total_top_household_devices.toLocaleString("en-US")}</b></Label>
                                    </div>
                                </div>}
                                {props.summaryState.pct_served > 0 && <div className={"column-2"}>
                                    <div><Label className={"field-label"}>Percent Served</Label></div>
                                    <div><Label
                                        className={"labels"}><b>{props.summaryState.pct_served.toLocaleString("en-US")}</b></Label>
                                    </div>
                                </div>}
                                {/*<div className={"column-4"}>*/}
                                {/*    <div><Label className={"labels"}>Billing Rate</Label></div>*/}
                                {/*    <div><Label*/}
                                {/*        className={"labels"}><b>{props.summaryState.billing_rate}</b></Label>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>
                        </>
                    )}
                    {props.targetType === "BUSINESS" && (
                        <>
                            <div className={"container"}>
                                {props.summaryState.total_businesses > 0 && <div className={"column-1"}>
                                    <div><Label className={"field-label"}>Matched</Label></div>
                                    <div><Label
                                        className={"labels"}><b>{props.summaryState.total_businesses.toLocaleString("en-US")}</b></Label>
                                    </div>
                                </div>}
                                {/*<div className={"column-2"}>*/}
                                {/*    <div><Label className={"field-label"}>Unique Phones</Label></div>*/}
                                {/*    <div><Label*/}
                                {/*        className={"labels"}><b>{props.summaryState.total_phones.toLocaleString("en-US")}</b></Label>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                {props.summaryState.total_business_network_ip_addresses > 0 && <div className={"column-3"}>
                                    <div><Label className={"field-label"}>Network IP Ranges </Label></div>
                                    <div><Label
                                        className={"labels"}><b>{props.summaryState.total_business_network_ip_addresses.toLocaleString("en-US")}</b></Label>
                                    </div>
                                </div>}
                                {props.summaryState.total_business_wifi_ip_addresses > 0 && <div className={"column-4"}>
                                    <div><Label className={"field-label"}>Wifi IP Ranges</Label></div>
                                    <div><Label
                                        className={"labels"}><b>{props.summaryState.total_business_wifi_ip_addresses.toLocaleString("en-US")}</b></Label>
                                    </div>
                                </div>}
                            </div>
                            <div className={"container"} style={{paddingTop: "1rem"}}>
                                {props.summaryState.total_business_devices > 0 && <div className={"column-1"}>
                                    <div><Label className={"field-label"}>Devices</Label></div>
                                    <div><Label
                                        className={"labels"}><b>{props.summaryState.total_business_devices.toLocaleString("en-US")}</b></Label>
                                    </div>
                                </div>}
                                {props.summaryState.total_top_business_devices > 0 && <div className={"column-2"}>
                                    <div><Label className={"field-label"}>Top Devices</Label></div>
                                    <div><Label
                                        className={"labels"}><b>{props.summaryState.total_top_business_devices.toLocaleString("en-US")}</b></Label>
                                    </div>
                                </div>}
                                {props.summaryState.pct_served > 0 && <div className={"column-2"}>
                                    <div><Label className={"field-label"}>Percent Served</Label></div>
                                    <div><Label
                                        className={"labels"}><b>{props.summaryState.pct_served.toLocaleString("en-US")}</b></Label>
                                    </div>
                                </div>}
                                {/*<div className={"column-3"}>*/}
                                {/*    <div><Label className={"labels"}>Billing Rate</Label></div>*/}
                                {/*    <div><Label*/}
                                {/*        className={"labels"}><b>{props.summaryState.billing_rate.toLocaleString("en-US")}</b></Label>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>
                        </>
                    )}
                </CardBody>
            </Card>
        </div>
    )
}

export default UploadedRecords;