import React, {useEffect, useState} from "react";
import {Label} from "@progress/kendo-react-labels";
import {Dialog, DialogActionsBar} from "@progress/kendo-react-dialogs";
import {Button, ButtonGroup} from "@progress/kendo-react-buttons";
import {Input, InputClearValue, InputSeparator, TextBox} from "@progress/kendo-react-inputs";
import {DropDownList} from "@progress/kendo-react-dropdowns";
import LoadOverlay from "../Components/SharedComponents/LoadOverlay";
import "./IdxTagManager.css";
import {semcastAxios, showErrorToastMessage, showSuccessToastMessage} from "../../Utils/Common";
import {ID_PLACE_HOLDER, TRACKING_PIXELS} from "../../api/constants";
import {getFTPSettings} from "../../actions/OnBoardingActions";
import {Tooltip} from "@progress/kendo-react-tooltip";
import {TreeList} from "@progress/kendo-react-treelist";
import angleLeftRightThemeColorIcon
    from "../Components/Segmentation/temporaryresources/images/arrow-left-right-theme-color.svg";
import {extendDataItem, mapTree, SvgIcon} from "@progress/kendo-react-common";
import {cancelIcon} from "@progress/kendo-svg-icons";
import PermissionsEditGroup from "../Components/Permission/PermissionsEditGroup";
import {UsersHelpers} from "../../helpers/UsersHelpers";
import {useFormik} from "formik";
import * as Yup from "yup";
import {loadUsers} from "../../actions/UserActions";

//This function needs to be outside of the main component to prevent rerender
const RowRender = (properties) => {
    const {row, props, onDrop, onDragStart} = properties;
    const additionalProps = {
        onDragStart: (e) => onDragStart(e, props),
        onDragOver: (e) => {
            e.preventDefault();
        },
        // onDrop: (e) => onDrop(e, props),
        draggable: true,
    };
    return React.cloneElement(
        row,
        {...row.props, ...additionalProps},
        row.props.children
    );
};
const NewIdxTagDialog = (props) => {

    const {
        dialogOpen, setDialogOpen, groupsArr,
        refreshTable, setShowTagCode, availableFTPConnections, user,setPixelTagId
    } = props;
    const [allSavedSettings, setAllSavedSettings] = useState(availableFTPConnections);
    const [labelValue, setLabelValue] = React.useState('');
    const [segmentNamingState, setSegmentNamingState] = useState({text: "Please Select...", value: '', id: 0});
    const [currentConnection, setCurrentConnection] = useState({text: "Please Select...", id: 0});
    // const [domain, setDomain] = useState("");
    // const [tag, setTag] = useState("");
    const [groupObj, setGroupObj] = useState({group_name: "Please Select...", id: "-1"});
    const [newGroupName, setNewGroupName] = useState("");
    const [groupsWithNewOption, setGroupsWithNewOption] = useState([]);
    const [createGroupDialogOpen, setCreateGroupDialogOpen] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState({group_name: "Please Select...", id: "-1"});
    const [createdGroupId, setCreatedGroupId] = useState(null);
    const [openEditCreateDialog, setOpenEditCreateDialog] = useState(false);
    const [groupOverlayActive, setGroupOverlayActive] = useState(false);
    const [allUsersRoles, setAllUsersRoles] = useState([]);
    const [userHelpers, setUserHelpers] = useState({});
    const [overlayActive, setOverlayActive] = useState(false);
    const [confirmed, setConfirmed] = useState("");
    const [checked, setChecked] = React.useState(false);
    const [topCategory, setTopCategory] = useState("");
    const [originalTree, setOriginalTree] = useState({});
    const [loadOverlay, setLoadOverlay] = useState(true);
    const [isLoaded, setIsLoaded] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(true);
    const [treeList2Count, setTreeList2Count] = React.useState([]);
    const [tempSearchValues1, setTempSearchValues1] = useState([]);
    const [tempSearchValues2, setTempSearchValues2] = useState([]);
    const [expand1, setExpand1] = useState(true);
    const [expand2, setExpand2] = useState(true);
    const [tabList, setTablist] = useState([]);
    const [selectedTab, setSelectedTab] = React.useState("");
    const subItemsField = 'items';
    const expandField = 'expanded';
    const dataItemKey = 'treeId';
    const [treeState, setTreeState] = React.useState({
        data1: [],
        data2: [],
        expanded1: [],
        expanded2: [],
        dataState1: {
            sort: [],
            filter: [],
        },
        dataState2: {
            sort: [],
            filter: [],
        },
    });

    const columns = [
        {
            field: "text",
            title: " ",
            width: "250px",
            expandable: true,
        },
    ];
    // useEffect(() => {
    //     setTempSearchValues1(treeState.data1);
    //     setTempSearchValues2(treeState.data2);
    // }, [treeState.data1.length > 0]);

    useEffect(() => {
        if (tabList.length > 0 && treeState.data1.length > 0) {
            setLoadOverlay(false)
        }

    }, [tabList, treeState]);
    useEffect(() => {
        if (treeState.data2.length > 0 && currentConnection.id !== 0) {
            setDisableSubmit(false);
        } else {
            setDisableSubmit(true);
        }
    }, [treeState, currentConnection]);
    useEffect(() => {
        //Select the first tab by default
        setSelectedTab(tabList[0])
    }, [tabList]);
    useEffect(() => {
        let newGroupsList = user?.isAdmin ? [{group_name: "Create New Group", id: "new"}, ...groupsArr] : [...groupsArr];
        if (!newGroupName) {
            console.log("OriginalSetting", newGroupsList);
            if (JSON.stringify(groupsWithNewOption) !== JSON.stringify(newGroupsList)) {
                setGroupsWithNewOption(newGroupsList);
            }
        }
    }, [groupsArr, newGroupName]);
    useEffect(() => {
        setUserHelpers(new UsersHelpers());
    }, []);
    const groupEditFormik = useFormik({
        initialValues: {
            group_name: '',
            group_id: '',
            userNRoles: []
        },
// We used Yup here.
        validationSchema: Yup.object().shape({
            group_name: Yup.string().required('Group Name is required'),
            userNRoles: Yup.array().of(
                Yup.object().shape({
                    user_id: Yup.string(),
                    user_name: Yup.string(),
                    role_id: Yup.number(),
                    group_id: Yup.number(),
                    founding_assignment: Yup.boolean()
                })
            )
        }),
        onSubmit: values => {
        },
    });
    function removeDigitalIdentifierEntry(jsonObj, keyToRemove) {
        // Check if the "Digital Identifier" key exists
        if (jsonObj["Digital Identifiers"]) {
            let digitalIdentifiers = jsonObj["Digital Identifiers"];
            // Check if the subKeyToRemove exists and remove it
            if (digitalIdentifiers[keyToRemove]) {
                delete digitalIdentifiers[keyToRemove];
            }
        }
        return jsonObj;
    }
    const handleNewGroupCreated = async (newGroupName) => {
        // console.log("handleNewGroupCreated1 newGroupName=", newGroupName);
        setNewGroupName(newGroupName);
        setOpenEditCreateDialog(false); // Close the dialog
        setOverlayActive(true);
        let newGroupArray;
        try {
            await semcastAxios.get("/v2/secure/attributor/group/accessiblepx")
                .then((res) => {
                    newGroupArray = res.data.data;
                })
        } catch (error) {
            console.log(error)
            showErrorToastMessage("Error accessing Group information. " + error);
        }
        // const newGroupArray = await getAvailableGroups(); // Assume this refreshes the groups list and includes the new group

        if (newGroupArray.length > 0) {
            // console.log("handleNewGroupCreated newGroupArray fetched and used immediately:", newGroupArray);
            const groups = newGroupArray.map(item => ({
                group_name: item.group_name,
                id: item.resource_group_id
            }));
            // console.log("handleNewGroupCreated2 groups=", groups);
            // Assuming the newly created group is included in the newly fetched list
            const newGroup = groups.find(group => group.group_name === newGroupName);
            // console.log("handleNewGroupCreated3 newGroup=", newGroup);
            if (newGroupName) {
                // props.formik.setFieldValue('group_id', newGroup.id);

                setCreatedGroupId(newGroup.id);
                // console.log("handleNewGroupCreated4 BeforeNewOption newGroup=", newGroupName);
                const newGroupIndex = groups.findIndex(group => group.group_name === newGroupName);
                // console.log("handleNewGroupCreated5 BeforeNewOption newGroupIndex=", newGroupIndex);

                let newGroupsList;
                if (newGroupIndex !== -1) {
                    const [removed] = groups.splice(newGroupIndex, 1);
                    // console.log("handleNewGroupCreated6 Removed group:", removed);
                    newGroupsList = [removed, { group_name: "Create New Group", id: "new" }, ...groups];
                } else {
                    newGroupsList = [{ group_name: "Create New Group", id: "new" }, ...groups];
                }
                // console.log("handleNewGroupCreated7 AfterNewGroupGroups=", groups);

                // console.log("handleNewGroupCreated8 newGroupsList=", newGroupsList);
                if (JSON.stringify(groupsWithNewOption) !== JSON.stringify(newGroupsList)) {
                    setGroupsWithNewOption(newGroupsList);
                    // console.log("handleNewGroupCreated8 NewGroupsWithNewOption=", groupsWithNewOption);
                    setSelectedGroup(newGroupsList.find(group => group.id === newGroup.id));
                    // console.log("selectedGroup1=", selectedGroup);
                }
            }
        } else {
            // console.error("newGroupArrayNotFetched");
        }
        setOverlayActive(false);
    };

    function transformToNestedArray(obj) {
        let idCounter = 1; // Initialize the ID counter

        function createObject(key, value) {
            return {
                treeId: idCounter++, // Assign unique ID and increment the counter
                text: key,
                value: value,
                items: []
            };
        }

        function recursiveTransform(obj) {
            return Object.entries(obj).map(([key, value]) => {
                if (Array.isArray(value)) {
                    // Handle array values by creating an item for each array element
                    return {
                        treeId: idCounter++,
                        text: key,
                        value: key,
                        items: value.map(item => createObject(item, item))
                    };
                } else if (typeof value === 'object' && value !== null) {
                    // Handle nested objects by processing them recursively
                    return {
                        treeId: idCounter++,
                        text: key,
                        value: key,
                        items: recursiveTransform(value)
                    };
                } else {
                    // Handle string or direct mapping values
                    return createObject(key, value);
                }
            });
        }

        return recursiveTransform(obj);
    }
    function tree2InitObj(jsonObj) {
        // Check if the "Digital Identifier" key exists
        if (jsonObj["Digital Identifiers"]) {
            let digitalIdentifiers = jsonObj["Digital Identifiers"];
            // Check if the Client ID exists and remove it
            if (digitalIdentifiers["Client ID"]) {
                return {
                    "Digital Identifiers": {
                        "Signal Matched Semcasting ID": "nvl(sha2(b.persistent_id, 256), ha.hashed_location_id) signal_matched_id, ha.hashed_person_id",
                        "Client ID": "i.client_id"
                    },
                }
            }else{
                return {
                    "Digital Identifiers": {
                        "Signal Matched Semcasting ID": "nvl(sha2(b.persistent_id, 256), ha.hashed_location_id) signal_matched_id, ha.hashed_person_id",
                    },
                }
            }
        }
        return [];
    }
    const initialTree = async (id) => {
        setTreeState({
            ...treeState,
            data1: [],
            data2: [],
        });
        setSelectedTab("");
        await semcastAxios.get(TRACKING_PIXELS.replace(ID_PLACE_HOLDER, id), {withCredentials: true}).then(result => result)
            .then(data => {
                console.log(data.data.data.available_return_properties)
                let jsonObjLeft;
                let jsonObjRight;
                if (data.data.data.available_return_properties) {
                    // jsonObj = data.data.data.available_return_properties;
                    jsonObjRight = tree2InitObj(data.data.data.available_return_properties);
                    jsonObjLeft = removeDigitalIdentifierEntry(data.data.data.available_return_properties, "Signal Matched Semcasting ID");
                    jsonObjLeft = removeDigitalIdentifierEntry(jsonObjLeft, "Client ID");
                    // setTopCategory('????');
                }
                console.log("jsonObj ", jsonObjLeft)
                setOriginalTree(jsonObjLeft);
                if (jsonObjLeft) {
                    setTreeState({
                        ...treeState,
                        data1: transformToNestedArray(jsonObjLeft),
                        data2: transformToNestedArray(jsonObjRight),
                        expanded1: transformToNestedArray(jsonObjLeft).map((i) => i.treeId),
                        expanded2: transformToNestedArray(jsonObjRight).map((i) => i.treeId),
                        dataState1: {
                            sort: [],
                            filter: [],
                        },
                        dataState2: {
                            sort: [],
                            filter: [],
                        },
                    })
                    setTempSearchValues1(transformToNestedArray(jsonObjLeft));
                    setTempSearchValues2(transformToNestedArray(jsonObjRight));
                    let tempList = []
                    transformToNestedArray(jsonObjLeft).forEach(item => {
                        tempList.push(item.text);
                    })
                    setTablist(tempList.sort((a, b) => a.localeCompare(b)));
                } else {
                    showErrorToastMessage("Error: Tree object is null or undefined. ", 10);
                }

            }).catch((error) => {
                console.log(error.response)
                if (error.response) {
                    console.log('Response Error:', error.response.data);
                    showErrorToastMessage("Response Error: " + error.message + ". " + error.response.data.data, 10);
                } else if (error.request) {
                    // The request was made but no response was received
                    console.log('Request Error:', error.request);
                    showErrorToastMessage("Request Error: " + error.message + ". " + error.request.data.data, 10);
                }
            })
    };

    useEffect(() => {
        setLoadOverlay(true);
        //User Id = 0 for new tag
        const id = 0
        initialTree(id).catch(error => console.log("Fetch Tree Data: " + error));
    }, [])
    const closeDialog = () => {
        setDialogOpen(false);
    }

    const resetGroupUserRolesForm = async (usersList) => {

        console.log("StartingrResetGroupUserRolesForm usersList=", usersList);
        let usersArr = usersList
        if (usersArr) {
            let userNRoles = usersArr.map(user => {
                return {
                    user_id: user.id,
                    user_name: `${user.first_name} ${user.last_name}`,
                    role_id: '',
                    group_id: '',
                    founding_assignment: false,
                    role_name: "Not a Member",
                }
            });

            console.log("ResetGroupUserRolesForm userNRoles=", userNRoles);
            setAllUsersRoles(userNRoles);

            await groupEditFormik.setValues({group_name: '', group_id: '', userNRoles})
            console.log("ResetGroupUserRolesForm userNRoles=", userNRoles);
        }
        // console.error("ResetGroupUserRolesForm userNRoles=", userNRoles);
    }
    const usersLoad = async () => {
        try {
            console.log("StartingUserSLoad");
            const values = await Promise.allSettled([loadUsers()]);
            console.log("UsersLoadVALUE:", values[0].value);

            // setUsers(values[0].value);

            await resetGroupUserRolesForm(values[0].value);
            return values;

        } catch (e) {
        }
    }
    const onChangeDropDown = async (e) => {

        if (e.value.id === "new") {
            // Open modal to create a new group
            setOverlayActive(true);

            try {
                await usersLoad();
                setOpenEditCreateDialog(true);
            } catch (error) {
                console.error("Failed to load users and reset roles:", error);
                // Optionally handle the error, e.g., show an error message to the user
            }
            setOverlayActive(false);

            // You will need to pass a prop or use context to open the dialog from the second file
        } else {
            setGroupObj(e.target.value);
            // props.formik.values.group_id = e.value.id;
            console.log("onChangeGroup value.id=", e.value.id)
            setSelectedGroup(e.target.value);
        }
    }
    const connections = allSavedSettings?.map((item, i) => {
        if (allSavedSettings.length > 0) {
            return (
                {text: item.connection_name, id: item.id, protocol: item.transfer_protocol}
            )
        } else {
            return (
                {text: "No Saved Connections", id: 0}
            )
        }
    });
    const createNewIdxTag = async () => {
        const requestBody = {
            selectPickerFTPConnection: currentConnection.id,
            group_id: selectedGroup.id,
            label: labelValue,
            segment_naming_type: segmentNamingState.value,
            ['Append Results']: transformToNestedObject(treeState.data2)
        }
        console.log("requestBody: ", requestBody)
        try {
            let response = await semcastAxios.post("/v2/secure/attributor/idx-pixels",
                requestBody,
                {withCredentials: true})
                .then((res) => {
                    console.log(res.data)
                    if (res.data.code === 200) {
                        setPixelTagId(res.data.data.id);
                        // setPixelDomain(res.data.data.domain);

                        refreshTable().then(() => {
                            showSuccessToastMessage(`IDX Tag created`, 5);
                            closeDialog();
                            setShowTagCode(true);
                        })
                    } else {
                        showErrorToastMessage(`Error occurred: IDX Tag not created`, 10);
                    }

                }).catch((error) => {
                    if (error.response) {
                        showErrorToastMessage("Response Error: " + error.response.data.data, 10);
                        console.log(error.response);
                    }
                    if (error.request) {
                        showErrorToastMessage("Request Error: " + error.request.data.data, 10);
                        console.log(error.request);
                    }
                });;

            console.log("createNewIdxTag response:", response);
        } catch (e) {
            console.log(e);
            showErrorToastMessage(`Error occurred: ` + e);
        }

    }
    const handleConnectionChange = (e) => {
        console.log(e)
        setCurrentConnection({
            ...currentConnection,
            ['text']: e.value.text,
            ['id']: e.value.id,
            ['protocol']: e.value.protocol,
        });
    };
    const handleTabOnClick = (e, val) => {
        e.preventDefault();
        setTreeState(
            {
                ...treeState,
                expanded1: treeState.data1.map((i) => i.treeId)
            })
        if (selectedTab === val) {
            setSelectedTab("")
        } else {
            switch (val) {
                case selectedTab !== "":
                    setSelectedTab("");
                    break;
                case val:
                    setSelectedTab(val);
                    break;
                default:
                // code block
            }
        }
    }

    function contains(text, term) {
        return text.toLowerCase().indexOf(term.toLowerCase()) >= 0;
    }

    function search(items, term) {
        return items.reduce((acc, item) => {
            if (contains(item.text, term)) {
                acc.push(item);
            } else if (item.items && item.items.length > 0) {
                let newItems = search(item.items, term);
                if (newItems && newItems.length > 0) {
                    acc.push({text: item.text, items: newItems /*, expanded: item.expanded*/});
                }
            }
            return acc;
        }, []);
    }

    const handleSearch1 = () => {
        let allExpanded = [];
        let value = document.querySelector('.dragndrop-search-box1').value;
        let newData = search(tempSearchValues1, value);
        //Expand all search result if search value is not empty
        if (value.length !== 0) {
            mapTree(newData.slice(), subItemsField, (item) => {
                allExpanded.push(item[dataItemKey]);
            });
            setTreeState({
                ...treeState,
                data1: newData,
                expanded1: allExpanded
            })
        } else {
            setTreeState({
                ...treeState,
                data1: newData,
                expanded1: newData.map((i) => i.treeId)
            })
        }


    }
    const handleSearch2 = () => {
        let allExpanded = [];
        let value = document.querySelector('.dragndrop-search-box2').value;
        let newData = search(tempSearchValues2, value);
        //Expand all search result if search value is not empty
        if (value.length !== 0) {
            mapTree(newData.slice(), subItemsField, (item) => {
                allExpanded.push(item[dataItemKey]);
            });
            setTreeState({
                ...treeState,
                data2: newData,
                expanded2: allExpanded
            })
        } else {
            setTreeState({
                ...treeState,
                data2: newData,
                expanded2: newData.map((i) => i.treeId)
            })
        }
    }

    function countObjectsWithoutItems(arr) {
        let count = 0;

        arr.forEach(obj => {
            // Check if the object has no 'items' property or if 'items' is an empty array
            if (!obj.items || obj.items.length === 0) {
                count++;
            } else {
                // Recursively check for nested items
                count += countObjectsWithoutItems(obj.items);
            }
        });

        return count;
    }

    useEffect(() => {
        const result = countObjectsWithoutItems(treeState.data2)
        setTreeList2Count(result);
    }, [treeState]);
    const handleChange = event => {
        setChecked(event.value);
    };

    const isValidDomain = (domain) => {
        const regex = /^(?!:\/\/)(?!www\.)(?!:\/\/www\.)[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/;
        return regex.test(domain);
    }

    function sortTreeOrder(data) {
        return data.sort((a, b) => {
            const indexA = tabList.indexOf(a.text);
            const indexB = tabList.indexOf(b.text);

            // If text is not found in B, it will return -1. You can adjust sorting for such cases if needed.
            return (indexA === -1 ? Infinity : indexA) - (indexB === -1 ? Infinity : indexB);
        });
    }

    const processData1 = () => {
        let {data1, dataState1} = treeState;
        const sortedData = sortTreeOrder(data1);
        if (selectedTab !== "") {
            data1 = sortedData.filter(item => item.text === selectedTab);
        }
        return addExpandField1(data1);
    };
    const processData2 = () => {
        let {data2, dataState2} = treeState;
        // const sortedData = sortTreeOrder(data2);
        return addExpandField2(data2);
    };
    const addExpandField1 = (dataTree) => {
        const expanded = treeState.expanded1;
        return mapTree(dataTree, subItemsField, (item) =>
            extendDataItem(item, subItemsField, {
                [expandField]: expanded.includes(item.treeId)
            })
        );
    };
    const addExpandField2 = (dataTree) => {
        const expanded = treeState.expanded2;
        return mapTree(dataTree, subItemsField, (item) =>
            extendDataItem(item, subItemsField, {
                [expandField]: expanded.includes(item.treeId)
            })
        );
    };
    const onTreeList1ExpandChange = (e) => {
        setTreeState({
            ...treeState,
            expanded1: e.value
                ? treeState.expanded1.filter((id) => id !== e.dataItem.treeId)
                : [...treeState.expanded1, e.dataItem.treeId],
        });
    };
    const onTreeList2ExpandChange = (e) => {
        setTreeState({
            ...treeState,
            expanded2: e.value
                ? treeState.expanded2.filter((id) => id !== e.dataItem.treeId)
                : [...treeState.expanded2, e.dataItem.treeId],
        });
    };
    const handleTreeList1StateChange = (event) => {
        setTreeState({
            ...treeState,
            dataState1: event.dataState,
        });
    };
    const handleTreeList2StateChange = (event) => {
        setTreeState({
            ...treeState,
            dataState2: event.dataState,
        });
    };

    const rowForTreeList1 = (row, props) => {
        return (
            <RowRender
                props={props}
                row={row}
                // onDrop={handleOnDropFirst}
                onDragStart={handleDragStartFirst}
            />
        );
    };
    const rowForTreeList2 = (row, props) => {
        return (
            <RowRender
                props={props}
                row={row}
                // onDrop={handleOnDropSecond}
                onDragStart={handleDragStartSecond}
            />
        );
    };
    const handleDragStartFirst = (e, props) => {
        const {dataItem, level} = props;
        setTreeState({
            ...treeState,
            dragFrom: 'first',
            dragged: {dataItem, level},
        });
    };

    const handleDragStartSecond = (e, props) => {
        const {dataItem, level} = props;
        //Prevent user from de-selecting Signal Matched Semcasting ID
        if(props.dataItem.text === "Signal Matched Semcasting ID" || props.dataItem.text === "Client ID"){
            return;
        }
        setTreeState({
            ...treeState,
            dragFrom: 'second',
            dragged: {dataItem, level},
        });
    };

    //Process data for adding items to tree
    function addToParent(A, B, input) {
        // Helper function to find the parent node by its value
        function findParent(array, value) {
            for (const node of array) {
                if (node.items && node.items.some(item => item.value === value)) {
                    return node; // Parent found
                }
                if (node.items) {
                    const parent = findParent(node.items, value);
                    if (parent) return parent;
                }
            }
            return null;
        }

        // Helper function to find or create the parent chain in B
        function findOrCreateParentChainInB(B, parentChain) {
            let currentLevel = B;

            for (const parent of parentChain) {
                let existingNode = currentLevel.find(node => node.value === parent.value);

                if (!existingNode) {
                    // Create a new node in B for this part of the chain
                    existingNode = { ...parent, items: [] };
                    currentLevel.push(existingNode);
                }

                currentLevel = existingNode.items;
            }

            return currentLevel; // Return the items array where the input should be added
        }

        // Helper function to sort an array alphabetically by the 'text' property
        function sortArray(array) {
            return array.sort((a, b) => a.text.localeCompare(b.text));
        }

        // Recursive function to sort objects and nested arrays
        function sortObjectAndArrays(node) {
            if (Array.isArray(node)) {
                node.forEach(sortObjectAndArrays); // Recursively sort each item in the array
                sortArray(node); // Sort the array itself
            } else if (node && typeof node === "object") {
                if (node.items) {
                    sortObjectAndArrays(node.items); // Recursively sort items in the object
                }
            }
        }

        // Helper function to merge items
        function mergeItems(existingNode, inputNode) {
            if (inputNode.items && inputNode.items.length > 0) {
                inputNode.items.forEach(inputItem => {
                    const existingItem = existingNode.items.find(item => item.value === inputItem.value);
                    if (existingItem) {
                        // Recursively merge items
                        mergeItems(existingItem, inputItem);
                    } else {
                        // Add the new item
                        existingNode.items.push({ ...inputItem });
                    }
                });
            }
        }

        // Step 1: Find the parent chain for the input object in A
        const parentChain = [];
        let currentParent = findParent(A, input.value);

        while (currentParent) {
            parentChain.unshift(currentParent); // Build the chain from root to the immediate parent
            currentParent = findParent(A, currentParent.value);
        }

        // Step 2: Ensure the parent chain exists in B
        const parentItemsInB = findOrCreateParentChainInB(B, parentChain);

        // Step 3: Add or merge the input object into B
        const existingNode = parentItemsInB.find(node => node.value === input.value);

        if (existingNode) {
            // Merge items if input has additional items
            mergeItems(existingNode, input);
        } else {
            // Add input as a new node
            parentItemsInB.push({ ...input });
        }

        // Step 4: Sort B and all nested arrays
        sortObjectAndArrays(B);

        return B;
    }

    function removeObjectAndParentByValueWithException(A, input) {
        // Deep copy the array A to avoid modifying the original array
        const copyA = JSON.parse(JSON.stringify(A));

        // Helper function to remove an object from its parent's 'items' array
        function removeObjectFromParent(array, value) {
            for (let i = 0; i < array.length; i++) {
                const node = array[i];

                // Skip removing if the item's value is "ha.hashed_location_id, ha.hashed_person_id" or "i.client_id"
                if (node.value === "ha.hashed_location_id, ha.hashed_person_id" || node.value === "i.client_id") {
                    continue;
                }

                // If we find the node with the matching value
                if (node.value === value) {
                    // If the node has siblings (i.e., more than one item in the parent array)
                    if (array.length > 1) {
                        // Remove the node from the parent's 'items' array
                        array.splice(i, 1);
                        return true;
                    } else {
                        // If the node is the only one in the array, remove it
                        array.splice(i, 1);
                        return true;
                    }
                }

                // If the node has children (items), search in those
                if (node.items && node.items.length > 0) {
                    const result = removeObjectFromParent(node.items, value);
                    if (result) {
                        // If we found and removed the object, return true to stop further search
                        if (node.items.length === 0) {
                            // If the node's items are empty after removal, remove the parent node
                            array.splice(i, 1);
                        }
                        return true;
                    }
                }
            }
            return false; // Return false if no match was found in this array level
        }

        // Call the helper function on the copy of A
        removeObjectFromParent(copyA, input.value);

        return copyA; // Return the modified copy of array A
    }

    function treeListItemClickUtil(tempSegList1, tempSegList2, dataItem, onClickList1) {


        //On TreeList1 Item Click
        if (onClickList1) {
            tempSegList2 = addToParent(treeState.data1, treeState.data2, dataItem);
            tempSegList1 = removeObjectAndParentByValueWithException(treeState.data1, dataItem);

            //On TreeList2 Item Click
        } else {

            tempSegList1 = addToParent(treeState.data2, treeState.data1, dataItem);
            tempSegList2 = removeObjectAndParentByValueWithException(treeState.data2, dataItem);
        }
        // console.log("tempSegList1", tempSegList1);
        // console.log("tempSegList2", tempSegList2)
        return {list1: tempSegList1, list2: tempSegList2}
    }
    function mergeInputIntoA(A, input) {
        // Helper function to merge `items` recursively
        const mergeItems = (originalItems, newItems) => {
            const merged = [...originalItems];

            newItems.forEach(newItem => {
                const match = merged.find(item => item.treeId === newItem.treeId);

                if (match) {
                    // If the item exists, merge its `items` recursively
                    match.items = mergeItems(match.items || [], newItem.items || []);
                } else {
                    // If the item does not exist, add it to the merged array
                    merged.push(newItem);
                }
            });

            return merged;
        };

        // Iterate through the input array
        input.forEach(inputNode => {
            // Find the matching node in array A
            const match = A.find(node => node.treeId === inputNode.treeId);

            if (match) {
                // Merge the `items` arrays for the matched node
                match.items = mergeItems(match.items || [], inputNode.items || []);
            } else {
                // If no match, add the input node to array A
                A.push(inputNode);
            }
        });

        return A;
    }
    const onTreeListItemClick1 = (e) => {
        let tempSegList2 = treeState.data2;
        let tempSegList1 = treeState.data1;

        // if (e.level.length === 1) {
        //     if (!tempSegList2.find(item => item.value === e.dataItem.value)) {
        //         tempSegList2.push(e.dataItem);
        //     } else {
        //         tempSegList2.map(item => {
        //             if (item.value === e.dataItem.value) {
        //                 item.items.push(...e.dataItem.items);
        //             }
        //         })
        //     }
        //     tempSegList1 = treeState.data1.filter(item => item.value !== e.dataItem.value);
        // } else {
        //     const values = treeListItemClickUtil(tempSegList1, tempSegList2, e.dataItem, true);
        //     tempSegList1 = values.list1;
        //     tempSegList2 = values.list2;
        // }
        if (e.level.length === 1) {
            if(!tempSegList2.find(item => item.value === e.dataItem.value)) {
                tempSegList2.push(e.dataItem);
            }else{
                tempSegList2.map(item => {
                    if (item.value === e.dataItem.value && item.value !== "Digital Identifiers") {
                        // item.items.push(...e.dataItem.items);
                        item.items = mergeInputIntoA(item.items,e.dataItem.items);
                    }else if (item.value === e.dataItem.value && item.value === "Digital Identifiers"){
                        const filteredItems = e.dataItem.items.filter(
                            subItem => subItem.text !== "Signal Matched Semcasting ID" && subItem.text !== "Client ID"
                        );
                        // if(item.items.find(item => item.text === 'Sampling')){
                        item.items = mergeInputIntoA(item.items,filteredItems);
                        // }else{
                        //     item.items.push(...filteredItems);
                        // }
                    }
                })
            }
            if(e.dataItem.value === "Digital Identifiers") {
                // const filteredItems = treeState.data2[0].items.filter(item => item.text !== "Signal Matched Semcasting ID");
                tempSegList1.map(item => {
                    if (item.value === e.dataItem.value) {
                        // Filter out values with "ha.hashed_location_id, ha.hashed_person_id"
                        // Push only the filtered items
                        item.items = e.dataItem.items.filter(
                            subItem => subItem.text === "Signal Matched Semcasting ID"  || subItem.text === "Client ID"
                        );
                    }

                });
                tempSegList1 = treeState.data1.filter(item => item.value !== e.dataItem.value);
            }else{
                tempSegList1 = treeState.data1.filter(item => item.value !== e.dataItem.value);
            }
        }else if (e.level.length !== 1){
            //Prevent user from de-selecting Signal Matched Semcasting ID
            if(e.dataItem.text === "Signal Matched Semcasting ID" || e.dataItem.text === "Client ID"){
                return;
            }
            const values = treeListItemClickUtil(tempSegList1, tempSegList2, e.dataItem, true);
            tempSegList1 = values.list1;
            tempSegList2 = values.list2;
        }

        setExpand1(false);
        setExpand2(false);
        // setTreeState({
        //     ...treeState,
        // })

        let allExpandedList2 = [];
        mapTree(treeState.data2.slice(), subItemsField, (item) => {
            allExpandedList2.push(item[dataItemKey]);
        });
        setTreeState(
            {
                ...treeState,
                data1: tempSegList1,
                data2: tempSegList2,
                expanded2: allExpandedList2
            });

        setTempSearchValues1(tempSegList1);
        setTempSearchValues2(tempSegList2);
    }
    const onTreeListItemClick2 = (e) => {
        let tempSegList2 = treeState.data2;
        let tempSegList1 = treeState.data1;
        const currentItem = e.dataItem;

        if (e.level.length === 1) {
            if(!tempSegList1.find(item => item.value === currentItem.value)) {
                if(currentItem.value === "Digital Identifiers"){
                    let tempItem = currentItem;
                    tempItem.items = tempItem.items.filter(item => item.text !== "Signal Matched Semcasting ID" && item.text !== "Client ID");
                    tempSegList1.push(tempItem);
                }else{
                    tempSegList1.push(currentItem);
                }

            }else{
                tempSegList1.map(item => {
                    if (item.value === currentItem.value && item.value !== "Digital Identifiers") {
                        // item.items.push(...currentItem.items);
                        item.items = mergeInputIntoA(item.items,currentItem.items);
                    }else if (item.value === currentItem.value && item.value === "Digital Identifiers"){
                        const filteredItems = currentItem.items.filter(
                            subItem => subItem.text !== "Signal Matched Semcasting ID" && subItem.text !== "Client ID"
                        );
                        // if(item.items.find(item => item.text === 'Sampling')){
                        item.items = mergeInputIntoA(item.items,filteredItems);
                        // }else{
                        //     item.items.push(...filteredItems);
                        // }
                    }
                })
            }
            if(currentItem.value === "Digital Identifiers") {
                // const filteredItems = treeState.data2[0].items.filter(item => item.text !== "Signal Matched Semcasting ID");
                tempSegList2.map(item => {
                    if (item.value === currentItem.value) {
                        // Filter out values with "ha.hashed_location_id, ha.hashed_person_id"
                        // Push only the filtered items
                        item.items = item.items.filter(
                            subItem => subItem.text === "Signal Matched Semcasting ID"  || subItem.text === "Client ID"
                        );
                    }
                })
            }else{
                tempSegList2 = treeState.data2.filter(item => item.value !== currentItem.value);
            }
        }else if (e.level.length !== 1){
            //Prevent user from de-selecting Signal Matched Semcasting ID
            if(currentItem.text === "Signal Matched Semcasting ID" || currentItem.text === "Client ID"){
                return;
            }
            const values = treeListItemClickUtil(tempSegList1, tempSegList2, currentItem, false);
            tempSegList1 = values.list1;
            tempSegList2 = values.list2;
        }


        setTreeState(
            {
                ...treeState,
                data1: tempSegList1,
                data2: tempSegList2
            });

        setTempSearchValues1(tempSegList1);
        setTempSearchValues2(tempSegList2);
    }

    const handleDiv1 = () => {
        if (treeState.dragFrom === 'second') {
            let tempSegList2 = treeState.data2;
            let tempSegList1 = treeState.data1;
            const {dragged} = treeState;
            const {dataItem, level} = dragged;

            if (level.length === 1) {
                if(!tempSegList1.find(item => item.value === dataItem.value)) {
                    tempSegList1.push(dataItem);
                }else{
                    tempSegList1.map(item => {
                        if (item.value === dataItem.value && item.value !== "Digital Identifiers") {
                            // item.items.push(...e.dataItem.items);
                            item.items = mergeInputIntoA(item.items,dataItem.items);
                        }else if (item.value === dataItem.value && item.value === "Digital Identifiers"){
                            const filteredItems = dataItem.items.filter(
                                subItem => subItem.text !== "Signal Matched Semcasting ID" && subItem.text !== "Client ID"
                            );
                            // if(item.items.find(item => item.text === 'Sampling')){
                            item.items = mergeInputIntoA(item.items,filteredItems);
                            // }else{
                            //     item.items.push(...filteredItems);
                            // }
                        }
                    })
                }
                if(dataItem.value === "Digital Identifiers") {
                    // const filteredItems = treeState.data2[0].items.filter(item => item.text !== "Signal Matched Semcasting ID");
                    tempSegList2.map(item => {
                        if (item.value === dataItem.value) {
                            // Filter out values with "ha.hashed_location_id, ha.hashed_person_id"
                            // Push only the filtered items
                            item.items = dataItem.items.filter(
                                subItem => subItem.text === "Signal Matched Semcasting ID"  || subItem.text === "Client ID"
                            );
                        }
                    })
                }else{
                    tempSegList2 = treeState.data2.filter(item => item.value !== dataItem.value);
                }
            }else if (level.length !== 1){
                //Prevent user from de-selecting Signal Matched Semcasting ID
                if(dataItem.text === "Signal Matched Semcasting ID" || dataItem.text === "Client ID"){
                    return;
                }
                const values = treeListItemClickUtil(tempSegList1, tempSegList2, dataItem, false);
                tempSegList1 = values.list1;
                tempSegList2 = values.list2;
            }

            setTreeState(
                {
                    ...treeState,
                    data1: tempSegList1,
                    data2: tempSegList2
                });

            setTempSearchValues1(tempSegList1);
            setTempSearchValues2(tempSegList2);

        }
    };
    const handleDiv2 = () => {

        if (treeState.dragFrom === 'first') {
            const {dragged} = treeState;
            const {dataItem, level} = dragged;
            let tempSegList2 = treeState.data2;
            let tempSegList1 = treeState.data1;


            if (level.length === 1) {
                if (!tempSegList2.find(item => item.value === dataItem.value)) {
                    tempSegList2.push(dataItem);
                } else {
                    tempSegList2.map(item => {
                        if (item.value === dataItem.value) {
                            item.items.push(...dataItem.items);
                        }
                    })
                }
                tempSegList1 = treeState.data1.filter(item => item.value !== dataItem.value);
            } else {
                const values = treeListItemClickUtil(tempSegList1, tempSegList2, dataItem, true);
                tempSegList1 = values.list1;
                tempSegList2 = values.list2;
            }


            setExpand1(false);
            setExpand2(false);
            // setTreeState({
            //     ...treeState,
            // })

            let allExpandedList2 = [];
            mapTree(treeState.data2.slice(), subItemsField, (item) => {
                allExpandedList2.push(item[dataItemKey]);
            });
            setTreeState(
                {
                    ...treeState,
                    data1: tempSegList1,
                    data2: tempSegList2,
                    expanded2: allExpandedList2
                });

            setTempSearchValues1(tempSegList1);
            setTempSearchValues2(tempSegList2);

        }
    };
    const expandAll = (e, tree) => {
        e.preventDefault();
        //Expand all search result
        let allExpanded = [];
        if (tree === '1') {
            mapTree(treeState.data1.slice(), subItemsField, (item) => {
                allExpanded.push(item[dataItemKey]);
            });
            setExpand1(false);
            setTreeState({
                ...treeState,
                expanded1: allExpanded
            })
        } else {
            mapTree(treeState.data2.slice(), subItemsField, (item) => {
                allExpanded.push(item[dataItemKey]);
            });
            setExpand2(false);
            setTreeState({
                ...treeState,
                expanded2: allExpanded
            })
        }
    };
    const collapseAll = (e, tree) => {
        e.preventDefault();
        //Collapse all search result
        if (tree === '1') {
            setExpand1(true)
            setTreeState({
                ...treeState,
                expanded1: []
            })
        } else {
            setExpand2(true)
            setTreeState({
                ...treeState,
                expanded2: []
            })
        }
    }

    function findKeysWithNonEmptyArray(obj) {
        const result = [];

        function traverse(currentObj, currentKey) {
            if (Array.isArray(currentObj) && currentObj.length > 0) {
                result.push(currentKey);
            } else if (typeof currentObj === 'object' && currentObj !== null) {
                for (const key in currentObj) {
                    traverse(currentObj[key], key);
                }
            }
        }

        traverse(obj, null);
        return result;
    }

    function transformToNestedObject(arr) {
        const result = {};
        let temp = findKeysWithNonEmptyArray(originalTree);


        // console.log(pushKeyValuesToArray(originalTree['Retail Store Visits']))
        arr.forEach(item => {
            if (item.items && item.items.length > 0) {
                if (temp.includes(item.text)) {
                    // If item.text is in temp, directly assign item.items array as value
                    result[item.text] = item.items.map(subItem => subItem.value);
                } else {
                    // Otherwise, recursively transform the nested items
                    result[item.text] = transformToNestedObject(item.items);
                }
            } else {
                // If no nested items, map the value directly
                result[item.text] = item.value;
            }
        });

        return result;
    }

    useEffect(() => {
        console.log(transformToNestedObject(treeState.data1))
        console.log(transformToNestedObject(treeState.data2))
    }, [treeState]);
    const clearFields = async () => {
        //User Id = 0 for new tag
        const id = 0
        await initialTree(id).catch(error => console.log("Fetch Tree Data: " + error));
        setCurrentConnection({text: "Please Select...", id: 0})
    }
    const segmentNamingData = [{
        text: 'Category',
        value: 'CATEGORY',
        id: 1
    }, {
        text: 'Website',
        value: 'URL',
        id: 2
    }, {
        text: 'None',
        value: 'NONE',
        id: 3
    }];

    const onSegmentNamingDropdownChange = (event) => {
        setSegmentNamingState({
            ...segmentNamingState,
            ['text']: event.value.text,
            ['value']: event.value.value,
            ['id']: event.value.id,
        });
    }

    const handleLabelTextBoxChange = React.useCallback(event => {
        setLabelValue(event.target.value);
    }, []);
    const handleLabelTextBoxClear = React.useCallback(() => {
        setLabelValue('');
    }, []);
    // console.log("conditions", segmentNamingState.id, treeState.data2.length, groupObj.id, labelValue.length, currentConnection)

    return (
        <div>
            <Dialog
                className={"idx-dialog dialog-standard"}
                title={"New IDX Tag"}
                closeIcon={false}
                width={"70vw"}
                height={"80vh"}
            >

                <div className={"idx-dialog-container"}>

                    {openEditCreateDialog && (
                        <PermissionsEditGroup
                            dialogMode={"new"}
                            editGroupObj={{}}
                            refreshTable={async () => false}
                            allUsersRoles={allUsersRoles}
                            // usersLoad = {usersLoad}
                            userHelpers={userHelpers}
                            openEditCreateDialog={createGroupDialogOpen}
                            setOpenEditCreateDialog={setOpenEditCreateDialog}
                            groupEditFormik={groupEditFormik}
                            isFromIdxTag={true}
                            onGroupCreated={handleNewGroupCreated} // Add this callback
                        />
                    )}
                    <LoadOverlay active={overlayActive}>
                        <div className={"idx-segment-naming idx-form-field"}>
                            <Label className={"field-label"}>Tag Type</Label>
                            {/*<LoadOverlay active={defaultGroupLoading} height={25} width={25}>*/}
                            <DropDownList
                                className={"dropdown-standard"}
                                onChange={e => onSegmentNamingDropdownChange(e)}
                                data={segmentNamingData}
                                value={segmentNamingState}
                                textField="text"
                                dataItemKey="id"
                                defaultItem={{text: "Please Select...", id: 0}}
                                style={{fontSize: "14px"}}
                            />

                        </div>
                        <div className={"idx-ftp-setting idx-form-field"}>
                            <Label className={"field-label"}>
                                Save to connection
                            </Label>
                            <DropDownList
                                data={connections}
                                textField="text"
                                dataItemKey="id"
                                onChange={(e) => handleConnectionChange(e)}
                                value={currentConnection}
                                defaultItem={{text: "Please Select...", id: 0}}
                                disabled={allSavedSettings.length === 0}
                                style={{fontSize: "14px"}}
                            />
                        </div>

                        <div className={"idx-save-to-group-div idx-form-field"}>
                            <Label className={"field-label"}>Save to Group</Label>
                            {/*<LoadOverlay active={defaultGroupLoading} height={25} width={25}>*/}
                            <DropDownList
                                // className={"dropdown-standard"}
                                // textField={"group_name"}
                                // value={selectedGroup}
                                // onChange={onChangeDropDown}
                                // data={groupsWithNewOption}
                                // dataItemKey={"id"}
                                data={groupsWithNewOption}
                                textField="group_name"
                                dataItemKey="id"
                                defaultItem={{group_name: "Please Select...", id: "-1"}}
                                onChange={onChangeDropDown}
                                value={selectedGroup}
                                className="fields dropdown-standard"
                            />
                            <div className={"idx-dialog-save-group-hint"}>You must be an Admin of a group with
                                permission to
                                create an IDX Tag.
                            </div>

                        </div>
                        <div className={"idx-dialog-tree"}>
                            <LoadOverlay active={loadOverlay} width={'100%'} height={'100px'}>
                                <div className="data-append-inner-wrapper">
                                    <Label>Append the following to the output file: </Label>
                                    <div className={"tree-table-wrapper mt-1 mb-3"}>
                                        <div className={"tab-button-group-wrapper"}>
                                            <div className="tab-button-group">
                                                <ButtonGroup>
                                                    {tabList.map((item, index) => {
                                                        return (
                                                            item !== "" ?
                                                                <Button
                                                                    className={`${selectedTab === item && "btn-primary"}`}
                                                                    togglable={true} selected={selectedTab === item}
                                                                    onClick={(e) => handleTabOnClick(e, item)}>
                                                                    {item}
                                                                </Button> :
                                                                ""
                                                        )
                                                    })}
                                                </ButtonGroup>
                                                <Tooltip
                                                    position="right"
                                                    anchorElement="target"
                                                    tooltipStyle={{
                                                        width: "15rem",
                                                        borderRadius: "0.25rem",
                                                    }}
                                                >
                                                    <i className="fa fa-question-circle-o tooltip-question-icon"
                                                       aria-hidden="true"
                                                       title={"Select any tab to see the filtered list. Deselect the selected tab to see the full list."}></i>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div className={"tree-outter-wrapper"}>
                                            <div className={"left-tree-wrapper"}
                                                 style={{width: "-webkit-fill-available"}}>
                                                <div className={"tree-tool-bar"}>
                                                    <Input className={"dragndrop-search-box1"} onChange={handleSearch1}
                                                           placeholder={'Search Segment...'}/>
                                                    <Button
                                                        onClick={e => expand1 ? expandAll(e, '1') : collapseAll(e, '1')}
                                                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                                                        style={{marginLeft: '10px'}}
                                                    >
                                                        {expand1 ? "Expand all" : "Collapse all"}
                                                    </Button>
                                                </div>
                                                <div
                                                    className={"drag-panel1"}
                                                    onDrop={handleDiv1}
                                                    onDragOver={(e) => {
                                                        e.preventDefault()
                                                    }}
                                                >
                                                    <TreeList
                                                        {...treeState.dataState1}
                                                        style={{
                                                            height: "50vh",
                                                            overflow: "auto",
                                                            marginTop: "20px",
                                                        }}
                                                        expandField={expandField}
                                                        subItemsField={subItemsField}
                                                        data={processData1()}
                                                        columns={columns}
                                                        onDataStateChange={handleTreeList1StateChange}
                                                        onExpandChange={onTreeList1ExpandChange}
                                                        onRowClick={onTreeListItemClick1}
                                                        rowRender={rowForTreeList1}
                                                    />
                                                </div>
                                            </div>
                                            <img alt="" src={angleLeftRightThemeColorIcon}
                                                 style={{
                                                     width: "50px",
                                                     alignSelf: "center",
                                                     paddingLeft: '5px',
                                                     paddingRight: '5px'
                                                 }}
                                            />
                                            <div className={"right-tree-wrapper"}
                                                 style={{width: "-webkit-fill-available"}}>

                                                <div className={"selected-count"}>Selected Segments
                                                    ({treeList2Count})
                                                </div>
                                                <div className={"tree-tool-bar"}>
                                                    <Input className={"dragndrop-search-box2"} onChange={handleSearch2}
                                                           placeholder={'Search Segment...'}/>
                                                    <Button
                                                        onClick={e => expand2 ? expandAll(e, '2') : collapseAll(e, '2')}
                                                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                                                        style={{marginLeft: '10px'}}
                                                    >
                                                        {expand2 ? "Expand all" : "Collapse all"}
                                                    </Button>
                                                </div>
                                                <div
                                                    className={"drag-panel2"}
                                                    onDrop={handleDiv2}
                                                    onDragOver={(e) => {
                                                        e.preventDefault()
                                                    }}
                                                >
                                                    <TreeList
                                                        style={{
                                                            height: "50vh",
                                                            overflow: "auto",
                                                            marginTop: "20px",
                                                        }}
                                                        expandField={expandField}
                                                        subItemsField={subItemsField}
                                                        data={processData2()}
                                                        columns={columns}
                                                        onDataStateChange={handleTreeList2StateChange}
                                                        onExpandChange={onTreeList2ExpandChange}
                                                        onRowClick={onTreeListItemClick2}
                                                        rowRender={rowForTreeList2}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </LoadOverlay>
                            <div className={"idx-label idx-form-field"}>
                                <Label className={"field-label"}>Description</Label>
                                {/*<LoadOverlay active={defaultGroupLoading} height={25} width={25}>*/}
                                <TextBox value={labelValue} onChange={handleLabelTextBoxChange}
                                         placeholder="Please Enter..." suffix={() => <React.Fragment>
                                    {labelValue !== '' && <InputClearValue onClick={handleLabelTextBoxClear}>
                                        <SvgIcon icon={cancelIcon}/>
                                    </InputClearValue>}
                                    <InputSeparator/>
                                </React.Fragment>}/>
                            </div>
                        </div>
                    </LoadOverlay>
                </div>
                <DialogActionsBar>
                    <div className={"idx-dialog-submit-button-div"}>
                        <Button
                            className={"button-standard button-white"}
                            type={'button'}
                            disabled={false}
                            onClick={() => {
                                closeDialog();
                            }}
                        >
                            Cancel
                        </Button>

                        <Button
                            className={"button-standard button-submit"}
                            type={'button'}
                            disabled={segmentNamingState.id === 0 || treeState.data2.length === 0 || selectedGroup.id === "-1" || labelValue.length === 0 || currentConnection.id === 0}
                            onClick={createNewIdxTag}
                        >
                            Create IDX Tag
                        </Button>
                    </div>
                </DialogActionsBar>
            </Dialog>
        </div>
    )
}

export default NewIdxTagDialog;