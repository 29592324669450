import React from "react";

import {Dialog, DialogActionsBar} from '@progress/kendo-react-dialogs';

import acxiom from "../Segmentation/temporaryresources/docs/acxiom.csv";
import address from "../Segmentation/temporaryresources/docs/address.csv";
import audienceacuity from "../Segmentation/temporaryresources/docs/audienceacuity_id.csv";
// import haddress from "../Segmentation/temporaryresources/docs/address.csv";
// import companyName from "../Segmentation/temporaryresources/docs/company_name.csv";
import company_name_and_naics from "../Segmentation/temporaryresources/docs/company_name_and_naics.csv";
import companyAddress from "../Segmentation/temporaryresources/docs/company_name_and_address.txt";
import datatrust from "../Segmentation/temporaryresources/docs/datatrust_id.csv";
import deviceId from "../Segmentation/temporaryresources/docs/device_id.csv";
import domainName from "../Segmentation/temporaryresources/docs/domain_name.csv";
import email from "../Segmentation/temporaryresources/docs/email.csv";
import freewheel from "../Segmentation/temporaryresources/docs/freewheel_ids.csv";
import healthwise from "../Segmentation/temporaryresources/docs/healthwise_keys.csv";
import i360_id from "../Segmentation/temporaryresources/docs/i360_id.csv"
import impressions from "../Segmentation/temporaryresources/docs/impressions.csv";
import internetSites from "../Segmentation/temporaryresources/docs/internet_sites.csv";
import internetKeywords from "../Segmentation/temporaryresources/docs/internet_keywords.csv";
import L2DataVoter from "../Segmentation/temporaryresources/docs/l2_data_voter_id.csv";
import latLong from "../Segmentation/temporaryresources/docs/lat_long.csv";
// import map from "../Segmentation/temporaryresources/docs/geo.csv";
import md5Email from "../Segmentation/temporaryresources/docs/md5_email.csv";
import mriid from "../Segmentation/temporaryresources/docs/mri_id.csv";
import pdi from "../Segmentation/temporaryresources/docs/pdi_id.csv";
import npiId from "../Segmentation/temporaryresources/docs/npi_id.csv";
import pubmatic from "../Segmentation/temporaryresources/docs/pubmatic_id.csv";
import purplelab from "../Segmentation/temporaryresources/docs/PurpleLabID.csv";
import semcastingid from "../Segmentation/temporaryresources/docs/persistent_id.csv";
import sha1Email from "../Segmentation/temporaryresources/docs/sha1_email.csv";
import sha256Email from "../Segmentation/temporaryresources/docs/sha256_email.csv";
import usPhone from "../Segmentation/temporaryresources/docs/us_phone.csv";
import voterId from "../Segmentation/temporaryresources/docs/voter_id.csv";
import xandrId from "../Segmentation/temporaryresources/docs/xandr_id.csv";
import zip11 from "../Segmentation/temporaryresources/docs/zip11.txt";
import zip5 from "../Segmentation/temporaryresources/docs/zip5.txt";
import zip9 from "../Segmentation/temporaryresources/docs/zip9.txt";

import {Button} from "@progress/kendo-react-buttons";
import {Label} from "@progress/kendo-react-labels";

import "./UnknownFileType.css";

const UnknownFileType = (props) => {

    return (
        <>
            {props.isOpenModel && (<Dialog width={"30%"} height={"80%"} title={"Unknown Match Key"} className={"unknown-filetype-dialog"} closeIcon={false}>
                <div className={"unknown-filetype-notification"}>
                    <h5>We could not tell what type of file you uploaded.</h5>
                    <Label className={"unknown-filetype-instructions"}>Please select a template to download from the list below, and ensure your data is formatted the same way,
                        or press <span onClick={props.handleClose} style={{cursor: 'pointer'}}>"OK"</span> and upload a different file.</Label>
                </div>
                <h5 className={"unknown-filetype-templates-text"}>Templates to Download: </h5>
                <div>
                    <ul className={"unknown-filetype-list-items"}>
                        <li className={"field-label"}><a href={acxiom} download="acxiom.csv">Acxiom</a></li>
                        <li><a href={address} download="address.csv">Address</a></li>
                        <li><a href={audienceacuity} download="audienceacuity_id.csv">Audience Acuity ID</a></li>
                        <li><a href={companyAddress} download="company_name_address.csv">Company Name and Address</a>
                        </li>
                        <li><a href={company_name_and_naics} download="company_name_naics.csv">Company Name and Naics
                            Code</a></li>
                        <li><a href={latLong} download="lat_long.csv">Coordinates</a></li>
                        <li><a href={datatrust} download="datatrust_id.csv">DataTrust ID</a></li>
                        <li><a href={deviceId} download="device_id.csv">Device ID</a></li>
                        <li><a href={domainName} download="domain_name.csv">Domain Name</a></li>
                        <li><a href={email} download="email.csv">Email</a></li>
                        <li><a href={freewheel} download="freewheel_ids.csv">FreeWheel ID</a></li>
                        <li><a href={address} download="humana_address.csv">H* Address</a></li>
                        <li><a href={healthwise} download="healthwise_keys.csv">HealthWise ID</a></li>
                        <li><a href={i360_id} download="i360_id.csv">i360 ID</a></li>
                        <li><a href={internetKeywords} download="internet_keyword.csv">Internet Keyword</a></li>
                        <li><a href={internetSites} download="internet_sites.csv">Internet Sites</a></li>
                        <li><a href={impressions} download="impressions.csv">IP and Timestamp</a></li>
                        <li><a href={L2DataVoter} download="l2data_voter_id.csv">L2Data Voter ID</a></li>
                        <li><a href={md5Email} download="md5_email.csv">Md5 Email</a></li>
                        <li><a href={mriid} download="mri_id.csv">MRI ID</a></li>
                        <li><a href={npiId} download="npi_id.csv">NPI ID</a></li>
                        <li><a href={pdi} download="pdi_id.csv">PDI ID</a></li>
                        <li><a href={usPhone} download="us_phone.csv">Phone Number</a></li>
                        <li><a href={pubmatic} download="pubmatic_id.csv">PubMatic ID</a></li>
                        <li><a href={purplelab} download="purple-md5.csv">Purple Md5 ID</a></li>
                        <li><a href={semcastingid} download="persistent_id.csv">Semcasting ID</a></li>
                        <li><a href={sha1Email} download="sha1_email.csv">Sha1 Email</a></li>
                        <li><a href={sha256Email} download="sha256_email.csv">Sha256 Email</a></li>
                        <li><a href={voterId} download="voter_id.csv">Voter ID</a></li>
                        <li><a href={xandrId} download="xandr_id.csv">Xandr ID</a></li>


                        {/*FD: 10026*/}
                        {/*<li><a href="/docs/geo.csv" download="geo.csv">Map</a></li>*/}
                        {/*<li><a href={map} download="geo.csv">Map</a></li>*/}


                        {/*<li><a href="/docs/datatrust_id.csv" download="datatrust_id.csv">DataTrust ID</a></li>*/}
                        {/*<li><a href={purplelab} download="purple-md5.csv">Purple Md5 ID</a></li>*/}
                        {/*<li><a href="/docs/voter_id.csv" download="voter_id.csv">Voter ID</a></li>*/}
                        {/*<li><a href="/docs/healthwise_id.csv" download="healthwise_id.csv">HW ID</a></li>*/}
                        <li><a href={zip5} download="zip5.csv">5 Digit Zip Codes</a></li>
                        <li><a href={zip9} download="zip9.csv">9 Digit Zip Codes</a></li>
                        <li><a href={zip11} download="zip11.csv">11 Digit Zip Codes</a></li>

                    </ul>
                </div>
                <DialogActionsBar layout={"end"}>
                <Button
                        className="dialog-save"
                        size="large"
                        onClick={() => {
                            props.handleClose()
                        }}>
                        OK
                    </Button>
                </DialogActionsBar>
            </Dialog>)}
        </>
    )
}

export default UnknownFileType;