import {React, useEffect} from "react";
import Header from "../main-Header/Header";
// import HomepageContent from "../Pages/HomepageContent";
// import Footer from "../Footer/Footer";
// import LandingPage from "../Pages/LandingPage";
import SplashLandingToolboxPage from "../Pages/SplashLandingToolboxPage";

import { GoogleReCaptchaProvider} from "react-google-recaptcha-v3";

// const Home = () => {
//   return (
//         <React.Fragment>
//           <Header />
//           <HomepageContent />
//           <Footer />
//         </React.Fragment>
//   );
// };

const HomeToolbox = () => {
    return (
        <div>
            {/*<LandingPage />*/}
            <SplashLandingToolboxPage/>
        </div>
    );
};

export default HomeToolbox;
