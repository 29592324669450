import React, {useEffect, useState} from "react";
import { filterBy } from "@progress/kendo-data-query";
import {getPpkDistrictSegments} from "../../../actions/AudienceDesignActions";
import {Label} from "@progress/kendo-react-labels";
import {MultiSelect} from "@progress/kendo-react-dropdowns";
import "./LegislativeDistricts.css";

const LegislativeDistricts = (props) => {

    const [value, setValue] = useState(props.controlsState ?
        props.controlsState.get("districts") : []);

    const [legislativeDistrictOptions, setLegislativeDistrictOptions] = useState([]);
    const [allData, setAllData] = useState([]);
    const [segmentGroup, setSegmentGroup] = useState('District');
    const [segmentCategory, setSegmentCategory] = useState("");
    const [segmentId, setSegmentId] = useState("");

    useEffect(() => {
        const queryParam = {};
        queryParam.segment_group = segmentGroup;
        if (segmentCategory !== "") queryParam.segment_category = segmentCategory;
        if (segmentId !== "") queryParam.segment_id = segmentId;
        getPpkDistrictSegments(queryParam).then((res) => {
            if (res) {
                let data = [];
                res.data.forEach((obj) => {
                    data.push({
                        text: obj.segment_name + " " + obj.description,
                        value: obj.segment_id,
                    });
                });
                data.sort((x, y) => {
                    let xp = x.value;
                    let yp = y.value;
                    return xp === yp ? 0 : xp < yp ? -1 : 1;
                })
                setLegislativeDistrictOptions(data);
            }
        });
    }, [segmentCategory, segmentId]);

    // This control is used in multiple places - one of which is Audience Design Edit
    // props.segmentEditInfo will be set when we come from the Edit segment page - here
    // we populate the control's value accordingly.
    useEffect(() => {
        console.log(props.segmentEditInfo);
        let arr = [];
        if (props.controlsState) return;
        if (props.segmentEditInfo && Object.entries(props.segmentEditInfo).length > 0) {
            if (props.segmentEditInfo.data.segment_design.primaryData.districts) {
                props.segmentEditInfo.data.segment_design.primaryData.districts.forEach(optionValue => {
                    legislativeDistrictOptions.find(option => {
                        if (option.value === optionValue) {
                            arr.push(option);
                        }
                        ;
                    })
                })
                setValue(arr);
            }
        }
    }, [props.segmentEditInfo]);

    const onLegislativeFilterChange = (e) => {
        const filter = e.filter;
        // if (filter.value.length < 2) {
        //     setSegmentCategory("");
        //     setSegmentId("");
        //     return;
        // }
        if (filter.value.length === 2) {
            setSegmentCategory(filter.value.toUpperCase());
            setSegmentId("");
        } else if (filter.value.length > 2) {
            //if length > 2 assume query is a list of segment IDs
            setSegmentId(filter.value.toUpperCase());
            setSegmentCategory("");
        }
        // else if (filter.value.length == 0) {
        //     setSegmentCategory("");
        // }
        const allData = legislativeDistrictOptions.slice();
        const newData = filter.value.length >= 1 ? filterBy(allData, filter) : allData;

        setLegislativeDistrictOptions(newData);
    };

    const onLegislativeDistrictChange = (e) => {
        console.log("in onLegislativeDistrictChange:", e);


        let values = [];
        setValue(e.value);
        e.value.forEach((val) => {
            values.push(val.value);
        });
        props.formik.values.segment_design.primaryData.districts = values;
        if (props.handleControlsState) {
            props.handleControlsState(new Map(props.controlsState.set("districts", e.value)));
        }
    };

    return (
            <div className="form-group">
              <MultiSelect
                  className={"fields legislative-districts-multi-select"}
                  data={legislativeDistrictOptions}
                  autoClose={false}
                  placeholder="Select..."
                  textField="text"
                  dataItemKey="value"
                  filterable={true}
                  onFilterChange={onLegislativeFilterChange}
                  onChange={onLegislativeDistrictChange}
                  size="large"
                  value={value}
                  // maxLength={2}
                  // style={{width: "32rem"}}
              />
              <Label style={{fontSize:"14px"}} className={"hint-standard"}>Enter the 2 letter state Abbreviation. FL, AZ, NH</Label>
            </div>
    );
}

export default LegislativeDistricts;
