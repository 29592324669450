import React, {useEffect, useRef, useState} from "react";
import {Card, CardBody, CardTitle} from "@progress/kendo-react-layout";
import {Label} from "@progress/kendo-react-labels";
import {Input, TextArea} from "@progress/kendo-react-inputs";
import {DropDownList} from "@progress/kendo-react-dropdowns";
import {Form, FormElement, FieldWrapper} from "@progress/kendo-react-form";
import {loadAccessibleGroups} from "../../../actions/UserActions";
import {segmentNameValidator} from "../../Pages/validators";
import "./AboutAudienceEdit.css";

const AboutAudienceEdit = (props) => {

    const [accessibleGroups, setAccessibleGroups] = useState([]);
    const [groupError, setGroupError] = useState("");
    const [segmentName, setSegmentName] = useState(props.audienceState && props.audienceState.segment_name ? props.audienceState.segment_name : "");
    // const [segmentName, setSegmentName] = useState("");
    const [segmentNameValid, setSegmentNameValid] = useState(true);
    const [msg, setMsg] = useState("");
    const [notes, setNotes] = useState("")

    const NOTES_OPTIONAL_MESSAGE = "(Optional) Use Notes to write a brief description about this segment.";

    const groups = accessibleGroups?.map((item, i) => {
        return (
            {text: item.group_name, id: item.resource_group_id}
        )
    });

    const defaultGroup = groups.find(group => {
        return group.id === props.audienceState.group_id;
    });

    const onBlurSegmentName = (e) => {
        if (e.target.value !== segmentName) {
            props.handleAudienceState(prevState => {
                return {...prevState, segment_name: e.target.value}
            });
        }
    };
useEffect(()=>{
    // console.log(props.audienceState.segment_name);
    setSegmentName(props.audienceState.segment_name)
},[props.audienceState.segment_name])
    const onChangeSegmentName = (e) => {
        props.setSubText(false)
        const msg = segmentNameValidator(e.value);
        if (msg.length > 0) {
            setSegmentNameValid(false);
            setMsg(msg);
            setSegmentName(e.value);
            props.handleSegmentName(e.value);
            inputRef.current.focus();
        } else {
            setSegmentNameValid(true);
            setMsg("");
            setSegmentName(e.value);
            props.handleSegmentName(e.value);
            if (e.value !== props.audienceState.originalSegmentName) {
                props.handleSegmentNameChanged(true);
            }
        }
    };

    const onChangeGroup = (e) => {
        props.handleAudienceState(prevState => {
            return {...prevState, group_id: e.value.id}}
        );
    };

    const onChangeNotes = (e) => {
        const value = e.value;
        setNotes(value);
        props.handleAudienceState(prevState => {
            return {...prevState, notes: e.value}}
        );
    };

    const onSegmentNameEnter = (e) => {
        const key = e.code;
        if (key === "Enter") e.preventDefault();
    };

    useEffect(() => {
        async function fetchAccessibleGroups() {
            const values = await Promise.allSettled([loadAccessibleGroups()])
            if (values?.length > 0 && values[0].value.length > 0) {
                setAccessibleGroups(values[0].value)
            } else {
                setGroupError("Could not retrieve groups.")
            }
        }
        fetchAccessibleGroups();
    }, []);

    const inputRef = useRef();

    // useEffect(() => {
    //     // console.log(segmentNameValid);
    //     console.log(segmentName)
    //     const msg = segmentNameValidator(segmentName);
    //     // console.log(segmentName,props.audienceState.segment_name)
    //     // console.log(msg)
    //     if (msg.length > 0) {
    //         inputRef.current.focus();
    //         setSegmentNameValid(false);
    //         setMsg(msg);
    //         // props.handleSegmentName(segmentName);
    //     } else {
    //         setSegmentNameValid(true);
    //         setMsg("");
    //         setSegmentName(segmentName);
    //         props.handleSegmentName(segmentName);
    //         // props.handleAudienceState(prevState => {
    //         //         return {...prevState, segment_name: e.value}
    //         // });
    //     }
    //     // console.log(!segmentNameValid)
    //     // if (!segmentNameValid) {
    //     //     inputRef.current.focus();
    //         // props.setSegmentNameValid(true);
    //     // }
    // }, [segmentName]);

    useEffect(() => {
        setNotes(props.audienceState.notes)
    }, [props.audienceState.notes]);

    return (
        <div className={"about-audience-outer-div"}>
            <Card className={"about-audience-card"}>
                <CardTitle>
                    <Label className="section-label">ABOUT AUDIENCE</Label>
                </CardTitle>
                <CardBody>
                    {/*<Targeting handleSubmit={handleSubmit} />*/}
                    <Form render={formRenderProps => <FormElement style={{
                        width: "100%"
                    }}>
                        <legend className={'k-form-legend spacingLine'}></legend>
                        <div className={"about-audience-container"}>
                            <div className={"about-audience-column-1"}>
                                <Form render={formRenderProps => <FormElement style={{
                                    width: "100%"
                                }}>
                                    {/*<FieldWrapper style={{marginLeft: "2rem", width:"38rem"}}>*/}
                                    <FieldWrapper>
                                        <Label
                                            // className={props.formik.touched && props.formik.touched.segment_name && props.formik.errors.segment_name || !props.segmentNameChanged ? "labels-error" : "labels"}>
                                            className={!segmentNameValid || (!props.segmentNameChanged && props.saveAsOperation) ? "labels-error" : "field-label"}>
                                            Segment Name
                                        </Label>
                                        <Input
                                            ref={inputRef}
                                            id={"segment_name"}
                                            key={props.audienceState.segment_name}
                                            onKeyDown={onSegmentNameEnter}
                                            name="segment_name"
                                            className={"k-input"}
                                            defaultValue={props.audienceState.segment_name}
                                            onChange={onChangeSegmentName}
                                            onBlur={onBlurSegmentName}
                                            style={{borderColor: !segmentNameValid || (!props.segmentNameChanged && props.saveAsOperation) ? "red" : ''}}/>
                                        {!segmentNameValid && (
                                            <span style={{color: "red"}}>{msg}</span>
                                        )}
                                        {props.subText ?
                                            <span style={{color: "red"}}>Name of new segment must be different than <strong>{props.audienceState.originalSegmentName}</strong> to proceed with Save As.</span> : ""}

                                    </FieldWrapper>
                                    {/*<FieldWrapper style={{marginLeft: "2rem", width:"38rem"}}>*/}
                                    <FieldWrapper className={"about-audience-save-to-group"}>
                                        <Label className={"field-label"} >Save to Group</Label>
                                        <DropDownList
                                            data={groups}
                                            textField="text"
                                            dataItemKey="id"
                                            onChange={onChangeGroup}
                                            defaultItem={defaultGroup ? defaultGroup : groups[0]}
                                            className="fields dropdown-standard"
                                        />
                                    </FieldWrapper>
                                </FormElement>} />
                            </div>
                            <div className={"about-audience-column-2"}>
                                <Form render={formRenderProps => <FormElement style={{
                                    width: "100%"
                                }}>
                                    {/*<FieldWrapper style={{marginLeft: "2rem", width:"45.5%"}}>*/}
                                    {/*    <Label className={"labels"} >Generate Insight Report</Label>*/}
                                    {/*    <Checkbox*/}
                                    {/*        size="large"*/}
                                    {/*        defaultChecked={props.formik && props.formik.values ? props.formik.values.generate_pdf_reports : ""}*/}
                                    {/*        onChange={onChangeGenerateReport}/>*/}
                                    {/*</FieldWrapper>*/}
                                    <FieldWrapper>
                                        <Label className={"field-label"} >Notes</Label>
                                        <TextArea
                                            className={"fields"}
                                            // defaultValue={notes}
                                            // style={{width: "85%"}}
                                            value={notes}
                                            maxLength={5000}
                                            placeholder={"Targeting new customers from mailing list"}
                                            rows={1}
                                            onChange={onChangeNotes}
                                        />
                                        <span className={"field-label about-audience-notes-optional"}>{NOTES_OPTIONAL_MESSAGE}</span>
                                    </FieldWrapper>
                                </FormElement>} />
                            </div>
                        </div>
                    </FormElement>} />
                </CardBody>
            </Card>
        </div>
    )
}

export default AboutAudienceEdit;