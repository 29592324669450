import React, {useEffect, useState, useRef} from "react";
import "./DataEnhancement.css";
import {Card} from "@progress/kendo-react-layout";
import {ComboBox} from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";

import DataAppendTree from "./DataAppendTree";
import LoadOverlay from "../Components/SharedComponents/LoadOverlay";
import {SelectedSegmentContext} from "../../App";
import { useBeforeUnload } from 'react-router-dom';
import { useHistory } from "react-router-dom";
const DataEnhancement = () => {
    // const { selectedSegment, setSelectedSegment } = React.useContext(SelectedSegmentContext);
    const defaultItem = {segment_name: "Please Select ...",id:0};
    const [segmentsList, setSegmentsList] = useState([]);
    const [tempSegmentsList, setTempSegmentsList] = useState([]);
    const [selectedSeg, setSelectedSeg] = useState();
    const [loadOverlay, setLoadOverlay] = useState(true);
    const comboBoxRef = useRef(null);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const SegId = urlParams.get("id");
    const SegName = urlParams.get("name");
    const [targetSegment] = React.useState([{id: SegId, name: SegName}]);

    useEffect(() => {
        if (segmentsList.length > 0) {
            setLoadOverlay(false);
        }
    }, [segmentsList]);

    useEffect(() => {
    }, [selectedSeg]);

    return (
        <>
            <div className="data-enhancement-outter-div">
                <div className="data-enhancement-content">
                    <Card className={"section-card select-segment"}>
                        <div className="card-content">
                            <div className="card-header">
                                {targetSegment[0]?.name}
                            </div>
                            <div className="append-tree-content">
                                <DataAppendTree
                                    selectedSeg={targetSegment[0]}
                                />
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
        </>
    );
}

export default DataEnhancement;
