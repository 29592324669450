import React, { useEffect, useState } from "react";
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import ProfilePage from "./ProfilePage";
import "./AdminTabsPage.css";
import RolesPage from "../Components/Permission/RolesPage";

import UsersPermissionsDashboard from "../Components/Permission/UsersPermissionsDashboard";
import GroupsPermissionsDashboard from "../Components/Permission/GroupsPermissionsDashboard";
// import RolesPermissionsDashboard from "../Components/Permission/RolesPermissionsDashboard";

import AdxTagManager from "./AdxTagManager";
import AccountDetails from "./AccountDetails";

//@ts-ignore
// import {AccountDetails as AccountDetailsSetup} from "../Setup/Pages/AccountsDetails";
//import AccountDetailsSetup from "../Setup/Pages/AccountsDetailsSetupOld";
import FTPS3DashV3 from "./FTPS3DashV3.tsx";
import {getFTPSettings} from "../../actions/OnBoardingActions";
import {USER} from "../../api/constants";
// import AccountPricing2 from "../Setup/Pages/AccountPricing";
import AppStatus from "../Setup/Pages/AppStatus";
import CreateAccount from "../Setup/Pages/CreateAccount";
import Billing from "../Setup/Pages/Billing";
import JobStatus from "../Setup/Pages/JobStatus";
import NotificationDashboard from "./NotificationDashboard";
import AccountDashboard from "../Setup/Pages/AccountDashboard";
import IdxTagManager from "./IdxTagManager";
import {UserContext} from "../../contexts/UserContext";

const AdminTabsPage = () => {
    const {user, setUser} = React.useContext(UserContext);

    const [selected, setSelected] = React.useState(sessionStorage.getItem('adminTabSelected') || 0);
    // const [user, setUser] = React.useState(JSON.parse(sessionStorage.getItem(USER)) || null);
    const isToolboxLogged = sessionStorage.getItem("isToolboxLogged");
    const [allConnDash, setAllConnDash] = useState([]);

    const getAllSavedSettingsDashboard = async () => {
        getFTPSettings().then((res) => {
            if (res && res.data.length) {
                let allConnectionsDash = res.data;
                allConnectionsDash.forEach((conn) => {
                    if(conn.transfer_protocol === "ftps" || conn.transfer_protocol === "sftp") {
                        conn.ftps3 = "FTP";
                    } else {
                        conn.ftps3 = "S3";
                    }
                })
                setAllConnDash(allConnectionsDash);
            }
        })
    }

    const handleSelect = e => {
        sessionStorage.setItem('adminTabSelected', e.selected);
        setSelected(e.selected);
    };

    const [selected2, setSelected2] = React.useState(sessionStorage.getItem('permissionsTabSelected') || 0);

    const handleSelect2 = e => {

        sessionStorage.setItem('permissionsTabSelected', e.selected);
        setSelected2(e.selected);
    };

    useEffect(() => {
        let adminTab = parseInt(sessionStorage.getItem('adminTabSelected'));
        let permTab = parseInt(sessionStorage.getItem('permissionsTabSelected'));

        if(typeof adminTab === 'number' && adminTab >= 0 && adminTab <= 4) setSelected(adminTab);
        else setSelected(0);

        if(typeof permTab === 'number' && permTab >= 0 && permTab <= 2) setSelected2(permTab);
        else setSelected2(0);


        getAllSavedSettingsDashboard();
    }, [])

    return (
        <div>
            <div
                className={"section-card admin-tabstrip-container-card box-shadow-standard section-card-outer-margins"}>
                <TabStrip selected={selected} onSelect={handleSelect} tabPosition={"top"}>
                    <TabStripTab title="Profile" contentClassName={"admin-tabstrip-user"}>
                        <ProfilePage/>
                    </TabStripTab>

                <TabStripTab title="Permissions">
                    <TabStrip selected={selected2} onSelect={handleSelect2} tabPosition={"left"}>
                        <TabStripTab title="Users">

                            <UsersPermissionsDashboard>

                            </UsersPermissionsDashboard>

                        </TabStripTab>
                        <TabStripTab title="Groups">

                            <GroupsPermissionsDashboard>

                            </GroupsPermissionsDashboard>

                        </TabStripTab>

                        {user?.isAdmin &&  <TabStripTab title="Roles">

                            <div>
                                <RolesPage/>
                            </div>
                        </TabStripTab>
                        }
                    </TabStrip>
                </TabStripTab>
                <TabStripTab title="Connections Manager">

                        <div>
                            <FTPS3DashV3 allConnDash={allConnDash} setAllConnDash={setAllConnDash}
                                         getAllSavedSettingsDashboard={getAllSavedSettingsDashboard}></FTPS3DashV3>
                        </div>

                    </TabStripTab>
                    <TabStripTab title="ADX Tag Manager">
                        <div>
                            <AdxTagManager/>
                        </div>
                    </TabStripTab>
                    {user?.isAdmin && <TabStripTab title="IDX Tag Manager">
                        <div>
                            <IdxTagManager/>
                        </div>
                    </TabStripTab>}
                <TabStripTab title="Notifications">
                    <div>
                        <NotificationDashboard/>
                    </div>
                </TabStripTab>

                {user?.isAdmin &&  <TabStripTab title="App Status" contentClassName="adminTab">
                    <div>
                        <AppStatus/>
                    </div>
                </TabStripTab>
                }
                {user?.isAdmin &&  <TabStripTab title="Billing">
                    <div>
                        <Billing/>
                    </div>
                </TabStripTab>
                }

                {user?.isAdmin &&  <TabStripTab title="Job Status">
                    <div>
                        <JobStatus/>
                    </div>
                </TabStripTab>
                }
                {user?.isAdmin &&  <TabStripTab title="Account">
                    <div>
                        <AccountDashboard create={false}/>
                    </div>
                </TabStripTab>
                }
                {/*{user?.isAdmin && <TabStripTab title="Account">*/}
                {/*    <div>*/}
                {/*        <AccountDetailsSetup create={false}/>*/}
                {/*    </div>*/}
                 {/*</TabStripTab>*/}
                {/*}*/}
                {user?.isAdmin && <TabStripTab title="Users">
                    <div>
                        <UsersPermissionsDashboard isFromAccount={true}/>
                    </div>
                </TabStripTab>
                }
            </TabStrip>
            </div>
        </div>
    )
}

export default AdminTabsPage;