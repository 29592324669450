import React, {useEffect, useState} from "react";
import AgeRange from "./AgeRange";
import AnnualRevenue from "./AnnualRevenue";
import DigitalActivityIndex from "./DigitalActivityIndex";
import EmployeesRange from "./EmployeesRange";
import EthnicBackground from "./EthnicBackground";
import EthnicGroup from "./EthnicGroup";
import GenderButtonGroup from "./GenderButtonGroup";
import HomeOwnershipButtonGroup from "./HomeOwnershipButtonGroup";
import HomeValueRange from "./HomeValueRange";
import IncomeRange from "./IncomeRange";
import InflationSensitivityIndex from "./InflationSensitivityIndex";
import LegislativeDistricts from "./LegislativeDistricts";
import Naics from "./Naics";
import PoliticalPartyButtonGroup from "./PoliticalPartyButtonGroup";
import SocialMatrix from "./SocialMatrix";
import {Card, CardBody, CardTitle} from "@progress/kendo-react-layout";
import {Field, Form, FormElement, FieldWrapper} from "@progress/kendo-react-form";
import {FloatingLabel, Label} from "@progress/kendo-react-labels";
import {Input, RadioGroup} from "@progress/kendo-react-inputs";
import {DropDownList} from "@progress/kendo-react-dropdowns";
import {useFormik} from "formik";
import "./TargetingFull.css";
import { Tooltip } from '@progress/kendo-react-tooltip';

const TargetingFull = (props) => {
    const [selectedTargetType, setSelectedTargetType] = useState(props.formik.values.target_type);
    const [selectionType, setSelectionType] = useState( "DISTINCT");

    const targetTypes = [
        { label: "Consumer", value: "CONSUMER"},
        { label: "Business", value: "BUSINESS"}
    ]

    const selectionTypes = [
        {label: "Chosen value can be in ANY selection", value: "DISTINCT"},
        {label: "Chosen value can be in ALL selections", value: "COMMON"}
    ]

    const handleTargetType = (e) => {
        setSelectedTargetType(e.value);
        props.formik.values.target_type = e.value;
        props.formik.values.segment_design.target_type = e.value;
    };

    // Removed - demo feedback - 11/27/23
    // const handleSelectionType = (e) => {
    //     setSelectionType(e.value);
    //     props.formik.values.segment_design.merge_type = e.value;
    // };

    const handleSubmit = (e) => {

    };

    return (
        <>
            <Card className={"targeting-full-outer-card"}>
                <CardTitle>
                    <Label className="section-label">TARGETING</Label>
                </CardTitle>
                <CardBody>

                    <Form render={formRenderProps => <FormElement style={{
                        width: "100%"
                    }}>
                            <legend className={'k-form-legend spacingLine'}></legend>
                            <div className={"targeting-full-container"}>
                                <div className={"targeting-full-column-1"}>
                                    <Form render={formRenderProps => <FormElement style={{
                                        width: "100%"
                                    }}>
                                        <FieldWrapper className={"targeting-first-column-field"}>
                                            <Label className={"field-label"} >Target Type</Label>
                                            <RadioGroup
                                                data={targetTypes}
                                                value={selectedTargetType}
                                                onChange={handleTargetType}
                                                layout="horizontal"
                                                style={{width:"100%", fontSize:".9rem"}}
                                            />
                                        </FieldWrapper>
                                        {selectedTargetType === "CONSUMER" && (
                                            <>
                                            <FieldWrapper className={"targeting-first-column-field"}>
                                                <span className={"tooltip-label-and-icon-wrapper"}>
                                                    <Label className={"field-label"}>Age Range</Label>
                                                    <Tooltip
                                                        position="right"
                                                        anchorElement="target"
                                                        tooltipStyle={{
                                                            width: "8rem",
                                                            borderRadius: "0.25rem",
                                                        }}
                                                    >
                                                        <i className="fa fa-question-circle-o tooltip-question-icon"
                                                           aria-hidden="true"
                                                           title={"Age Range."}></i>
                                                    </Tooltip>
                                                </span>
                                                <AgeRange formik={props.formik}/>
                                            </FieldWrapper>
                                            <FieldWrapper className={"targeting-first-column-field"}>
                                                <span className={"tooltip-label-and-icon-wrapper"}>
                                                    <Label className={"field-label"}>Income Range</Label>
                                                    <Tooltip
                                                        position="right"
                                                        anchorElement="target"
                                                        tooltipStyle={{
                                                            width: "15rem",
                                                            borderRadius: "0.25rem",
                                                        }}
                                                    >
                                                        <i className="fa fa-question-circle-o tooltip-question-icon"
                                                           aria-hidden="true"
                                                           title={"Income Range specifies estimated Income ranges."}></i>
                                                    </Tooltip>
                                                </span>
                                                <IncomeRange formik={props.formik}/>
                                            </FieldWrapper>
                                            {/*FD:13400*/}
                                                <FieldWrapper className={"targeting-first-column-field"}>
                                                    <div className={"targeting-full-row"}>
                                                        <div className={"column"}>
                                                        <span className={"tooltip-label-and-icon-wrapper"}>
                                                            <Label className={"field-label"}>Home Ownership</Label>
                                                            <Tooltip
                                                                position="right"
                                                                anchorElement="target"
                                                                tooltipStyle={{
                                                                    width: "15rem",
                                                                    borderRadius: "0.25rem",
                                                                }}
                                                            >
                                                                <i className="fa fa-question-circle-o tooltip-question-icon"
                                                                   aria-hidden="true"
                                                                   title={"Home Ownership identifies an individual as a Home Owner, Renter or both."}></i>
                                                            </Tooltip>
                                                        </span>
                                                            <HomeOwnershipButtonGroup formik={props.formik}/>
                                                        </div>
                                                        <div className={"column"}>
                                                        <span className={"tooltip-label-and-icon-wrapper"}>
                                                            <Label className={"field-label"}>Gender</Label>
                                                            <Tooltip
                                                                position="right"
                                                                anchorElement="target"
                                                                tooltipStyle={{
                                                                    width: "15rem",
                                                                    borderRadius: "0.25rem",
                                                                }}
                                                            >
                                                                <i className="fa fa-question-circle-o tooltip-question-icon"
                                                                   aria-hidden="true"
                                                                   title={"Gender identifies individuals as Men or Women."}></i>
                                                            </Tooltip>
                                                        </span>
                                                            <GenderButtonGroup formik={props.formik}/>
                                                        </div>
                                                    </div>
                                                </FieldWrapper>
                                                <FieldWrapper className={"targeting-first-column-field"}>
                                                <span className={"tooltip-label-and-icon-wrapper"}>
                                                    <Label className={"field-label"}>Home Value</Label>
                                                    <Tooltip
                                                        position="right"
                                                        anchorElement="target"
                                                        tooltipStyle={{
                                                            width: "15rem",
                                                            borderRadius: "0.25rem",
                                                        }}
                                                    >
                                                        <i className="fa fa-question-circle-o tooltip-question-icon"
                                                           aria-hidden="true"
                                                           title={"Home Value specifies estimated Home Value ranges."}></i>
                                                    </Tooltip>
                                                </span>
                                                    <HomeValueRange formik={props.formik}/>
                                                </FieldWrapper>
                                                {/*FD:13400*/}
                                                {/*<FieldWrapper className={"targeting-first-column-field"}>*/}
                                                {/*    <div className={"targeting-full-row"}>*/}
                                                {/*        <div className={"column"}>*/}
                                                {/*            <span className={"tooltip-label-and-icon-wrapper"}>*/}
                                                {/*                <Label className={"field-label"}>Home Ownership</Label>*/}
                                                {/*                <Tooltip*/}
                                                {/*                    position="right"*/}
                                                {/*                    anchorElement="target"*/}
                                                {/*                    tooltipStyle={{*/}
                                                {/*                        width: "15rem",*/}
                                                {/*                        borderRadius: "0.25rem",*/}
                                                {/*                    }}*/}
                                                {/*                >*/}
                                                {/*                    <i className="fa fa-question-circle-o tooltip-question-icon"*/}
                                                {/*                       aria-hidden="true"*/}
                                                {/*                       title={"Home Ownership identifies an individual as a Home Owner, Renter or both."}></i>*/}
                                                {/*                </Tooltip>*/}
                                                {/*            </span>*/}
                                                {/*            <HomeOwnershipButtonGroup formik={props.formik}/>*/}
                                            {/*        </div>*/}
                                            {/*        <div className={"column"}>*/}
                                            {/*            <span className={"tooltip-label-and-icon-wrapper"}>*/}
                                            {/*                <Label className={"field-label"}>Gender</Label>*/}
                                            {/*                <Tooltip*/}
                                            {/*                    position="right"*/}
                                            {/*                    anchorElement="target"*/}
                                            {/*                    tooltipStyle={{*/}
                                            {/*                        width: "15rem",*/}
                                            {/*                        borderRadius: "0.25rem",*/}
                                            {/*                    }}*/}
                                            {/*                >*/}
                                            {/*                    <i className="fa fa-question-circle-o tooltip-question-icon"*/}
                                            {/*                       aria-hidden="true"*/}
                                            {/*                       title={"Gender identifies individuals as Men or Women."}></i>*/}
                                            {/*                </Tooltip>*/}
                                            {/*            </span>*/}
                                            {/*            <GenderButtonGroup formik={props.formik}/>*/}
                                            {/*        </div>*/}
                                            {/*    </div>*/}
                                            {/*</FieldWrapper>*/}
                                            {/*<FieldWrapper className={"targeting-first-column-field"}>*/}
                                            {/*    <span className={"tooltip-label-and-icon-wrapper"}>*/}
                                            {/*        <Label className={"field-label"}>Political Party</Label>*/}
                                            {/*        <Tooltip*/}
                                            {/*            position="right"*/}
                                            {/*            anchorElement="target"*/}
                                            {/*            tooltipStyle={{*/}
                                            {/*                width: "15rem",*/}
                                            {/*                borderRadius: "0.25rem",*/}
                                            {/*            }}*/}
                                            {/*        >*/}
                                            {/*            <i className="fa fa-question-circle-o tooltip-question-icon" aria-hidden="true" title={"Political Party is an organization that coordinates candidates to compete in a particular country's elections."}></i>*/}
                                            {/*        </Tooltip>*/}
                                            {/*    </span>*/}
                                            {/*    <PoliticalPartyButtonGroup formik={props.formik}/>*/}
                                            {/*</FieldWrapper>*/}
                                            {/*<FieldWrapper className={"targeting-first-column-field targeting-full-legislative-div"}>*/}
                                            {/*    <span className={"tooltip-label-and-icon-wrapper"}>*/}
                                            {/*        <Label className={"field-label"}>*/}
                                            {/*            Legislative Districts*/}
                                            {/*        </Label>*/}

                                            {/*        <Tooltip*/}
                                            {/*            position="right"*/}
                                            {/*            anchorElement="target"*/}
                                            {/*            tooltipStyle={{*/}
                                            {/*                width: "15rem",*/}
                                            {/*                borderRadius: "0.25rem",*/}
                                            {/*            }}*/}
                                            {/*        >*/}
                                            {/*                  <i className="fa fa-question-circle-o tooltip-question-icon" aria-hidden="true" title={"Legislative Districts refer to areas from which members are elected to state or equivalent entity legislatures."}></i>*/}
                                            {/*        </Tooltip>*/}
                                            {/*    </span>*/}
                                            {/*    /!*<Label className={"field-label"}>Legislative Districts</Label>*!/*/}
                                            {/*    <LegislativeDistricts formik={props.formik}/>*/}
                                            {/*</FieldWrapper>*/}
                                            </>
                                        )}
                                    </FormElement>} />
                                </div>
                                <div className={"targeting-full-column-2"}>
                                    <Form render={formRenderProps => <FormElement style={{
                                        width: "100%"
                                    }}>
                                        <FieldWrapper className={"targeting-second-column-field"}>
                                            <Label className={"field-label targeting-selection-type-label"} style={{paddingBottom:"3.4rem"}}></Label>
                                            {/*Removed - demo feedback - 1/27/23*/}
                                            {/*<RadioGroup*/}
                                            {/*    data={selectionTypes}*/}
                                            {/*    value={selectionType}*/}
                                            {/*    onChange={handleSelectionType}*/}
                                            {/*    layout="vertical"*/}
                                            {/*    style={{width:"100%", fontSize:"16px"}}*/}
                                            {/*/>*/}
                                        </FieldWrapper>
                                        {selectedTargetType === "CONSUMER" && (
                                            <>
                                            {/*<FieldWrapper className={"targeting-second-column-field"}>*/}
                                            {/*    <span className={"tooltip-label-and-icon-wrapper"}>*/}
                                            {/*        <Label className={"field-label"}>*/}
                                            {/*            Ethnic Group*/}
                                            {/*        </Label>*/}

                                            {/*        <Tooltip*/}
                                            {/*            position="right"*/}
                                            {/*            anchorElement="target"*/}
                                            {/*            tooltipStyle={{*/}
                                            {/*                width: "15rem",*/}
                                            {/*                borderRadius: "0.25rem",*/}
                                            {/*            }}*/}
                                            {/*            tooltipClassName={"tooltip-form"}*/}
                                            {/*        >*/}
                                            {/*                  <i className="fa fa-question-circle-o tooltip-question-icon" aria-hidden="true" title={"Ethnic Group refers to a specific community of people who share a common cultural, linguistic, and/or religious identity, and who are recognized as a distinct social group."}></i>*/}
                                            {/*        </Tooltip>*/}

                                            {/*    </span>*/}
                                            {/*    <EthnicGroup formik={props.formik}/>*/}
                                            {/*</FieldWrapper>*/}
                                            {/*<FieldWrapper className={"targeting-second-column-field"}>*/}
                                            {/*    <span className={"tooltip-label-and-icon-wrapper"}>*/}
                                            {/*       <Label className={"field-label"}>*/}
                                            {/*           Ethnic Background*/}
                                            {/*       </Label>*/}

                                            {/*        <Tooltip*/}
                                            {/*            position="right"*/}
                                            {/*            anchorElement="target"*/}
                                            {/*            tooltipStyle={{*/}
                                            {/*                width: "15rem",*/}
                                            {/*                borderRadius: "0.25rem",*/}
                                            {/*            }}*/}
                                            {/*        >*/}
                                            {/*                  <i className="fa fa-question-circle-o tooltip-question-icon" aria-hidden="true" title={"Ethnic Background refers to a person's individual cultural and ancestral heritage, such as their language, customs, and traditions."}></i>*/}
                                            {/*        </Tooltip>*/}
                                            {/*    </span>*/}
                                            {/*    /!*<Label className={"field-label"}>Ethnic Background</Label>*!/*/}
                                            {/*    <EthnicBackground formik={props.formik}/>*/}
                                            {/*</FieldWrapper>*/}
                                             <FieldWrapper className={"targeting-second-column-field"}>
                                                 <span className={"tooltip-label-and-icon-wrapper"}>
                                                    <Label className={"field-label"}>
                                                        Social Matrix
                                                    </Label>

                                                    <Tooltip
                                                        position="right"
                                                        anchorElement="target"
                                                        tooltipStyle={{
                                                            width: "15rem",
                                                            borderRadius: "0.25rem",
                                                        }}
                                                    >
                                                              <i className="fa fa-question-circle-o tooltip-question-icon" aria-hidden="true" title={"Social Matrix refers to personas based on Age, Life Style & Affluence."}></i>
                                                    </Tooltip>

                                                </span>

                                                 {/*<Label className={"field-label"}>Social Matrix</Label>*/}
                                                 <SocialMatrix formik={props.formik}/>
                                             </FieldWrapper>
                                             <FieldWrapper className={"targeting-second-column-field"}>
                                                <span className={"tooltip-label-and-icon-wrapper"}>
                                                    <Label className={"field-label"}>
                                                        Digital Activity Index
                                                    </Label>

                                                    <Tooltip
                                                        position="right"
                                                        anchorElement="target"
                                                        tooltipStyle={{
                                                            width: "15rem",
                                                            borderRadius: "0.25rem",
                                                        }}
                                                    >
                                                              <i className="fa fa-question-circle-o tooltip-question-icon" aria-hidden="true" title={"Digital Activity Index refers to the measurement of internet engagement and time online."}></i>
                                                    </Tooltip>

                                                </span>

                                                 {/*<Label className={"field-label"}>Digital Activity Index</Label>*/}
                                                 <DigitalActivityIndex formik={props.formik}/>
                                             </FieldWrapper>
                                             <FieldWrapper className={"targeting-second-column-field"}>
                                                <span className={"tooltip-label-and-icon-wrapper"}>
                                                    <Label className={"field-label"}>
                                                        Inflation Sensitivity Index
                                                    </Label>

                                                    <Tooltip
                                                        position="right"
                                                        anchorElement="target"
                                                        tooltipStyle={{
                                                            width: "15rem",
                                                            borderRadius: "0.25rem",
                                                        }}
                                                    >
                                                              <i className="fa fa-question-circle-o tooltip-question-icon" aria-hidden="true" title={"Inflation Sensitivity Index identifies how vulnerable a particular household may be in the current economic climate."}></i>
                                                    </Tooltip>

                                                </span>

                                                 {/*<Label className={"field-label"}>Inflation Sensitivity Index</Label>*/}
                                                 <InflationSensitivityIndex formik={props.formik}/>
                                             </FieldWrapper>
                                            </>
                                        )}
                                    </FormElement>} />
                                </div>
                            </div>
                    </FormElement>} />

                    {selectedTargetType === "BUSINESS" && (
                        <>
                            <div className="targeting-full-container">
                                <div className={"targeting-full-column-1"}>
                                    <Form render={formRenderProps => <FormElement style={{
                                        width: "100%"
                                    }}>
                                        <FieldWrapper style={{paddingTop:"1rem"}} className={"targeting-first-column-field"}>
                                            <span className={"tooltip-label-and-icon-wrapper"}>
                                            <Label className={"field-label"}>Annual Revenue</Label>
                                                <Tooltip
                                                    position="right"
                                                    anchorElement="target"
                                                    tooltipStyle={{
                                                        width: "15rem",
                                                        borderRadius: "0.25rem",
                                                    }}
                                                >
                                                              <i className="fa fa-question-circle-o tooltip-question-icon"
                                                                 aria-hidden="true"
                                                                 title={"Annual Revenue specifies Revenue estimate ranges."}></i>
                                                    </Tooltip>
                                                </span>
                                            <AnnualRevenue formik={props.formik}/>
                                        </FieldWrapper>
                                        <FieldWrapper className={"targeting-first-column-field"}>
                                            <span className={"tooltip-label-and-icon-wrapper"}>
                                                    <Label className={"field-label"}>
                                                        NAICS Codes
                                                    </Label>

                                                    <Tooltip
                                                        position="right"
                                                        anchorElement="target"
                                                        tooltipStyle={{
                                                            width: "15rem",
                                                            borderRadius: "0.25rem",
                                                        }}
                                                    >
                                                              <i className="fa fa-question-circle-o tooltip-question-icon" aria-hidden="true" title={"NAICS Codes refer to 3 or 6 digit Industry Codes."}></i>
                                                    </Tooltip>
                                                </span>


                                            {/*<Label className={"field-label"}>NAICS Codes</Label>*/}
                                            <Naics formik={props.formik}/>
                                        </FieldWrapper>
                                    </FormElement>} />
                                </div>
                                <div className={"targeting-full-column-2"}>
                                    <Form render={formRenderProps => <FormElement style={{
                                        width: "100%"
                                    }}>
                                        <FieldWrapper style={{paddingTop:"1rem"}} className={"targeting-second-column-field"}>
                                            <span className={"tooltip-label-and-icon-wrapper"}>
                                            <Label className={"field-label"}>Number of Employees</Label>
                                                <Tooltip
                                                    position="right"
                                                    anchorElement="target"
                                                    tooltipStyle={{
                                                        width: "15rem",
                                                        borderRadius: "0.25rem",
                                                    }}
                                                >
                                                              <i className="fa fa-question-circle-o tooltip-question-icon" aria-hidden="true" title={"Number of Employees specify ranges for the Number of Employees at a business."}></i>
                                                    </Tooltip>
                                                </span>
                                            <EmployeesRange formik={props.formik}/>
                                        </FieldWrapper>
                                    </FormElement>} />
                                </div>
                            </div>
                        </>
                    )}
                </CardBody>
            </Card>
        </>
    )
}

export default TargetingFull;