import React, {useEffect, useState} from "react";
import { filterBy } from "@progress/kendo-data-query";
import {getPpkDistrictSegments} from "../../../actions/AudienceDesignActions";
import {Label} from "@progress/kendo-react-labels";
import {MultiSelect} from "@progress/kendo-react-dropdowns";
import "./LegislativeDistricts.css";

const LegislativeDistricts = (props) => {

    const [value, setValue] = useState([]);

    const [legislativeDistrictOptions, setLegislativeDistrictOptions] = useState([]);
    const [allData, setAllData] = useState([]);
    const [segmentGroup, setSegmentGroup] = useState('District');
    const [segmentCategory, setSegmentCategory] = useState("");
    const [segmentId, setSegmentId] = useState("");

    useEffect(() => {
        const queryParam = {};
        queryParam.segment_group = segmentGroup;
        if (segmentCategory !== "") queryParam.segment_category = segmentCategory;
        if (segmentId !== "") queryParam.segment_id = segmentId;
        getPpkDistrictSegments(queryParam).then((res) => {
            if (res) {
                let data = [];
                res.data.forEach((obj) => {
                    data.push({
                        text: obj.segment_name + " " + obj.description,
                        value: obj.segment_id,
                    });
                });
                data.sort((x, y) => {
                    let xp = x.value;
                    let yp = y.value;
                    return xp === yp ? 0 : xp < yp ? -1 : 1;
                })
                setLegislativeDistrictOptions(data);
            }
        });
    }, [segmentCategory, segmentId]);

    useEffect(() => {
        let arr = [];
        props.audienceState.districts.forEach(optionValue => {
            legislativeDistrictOptions.find(option => {
                if (option.value === optionValue) {
                    arr.push(option);
                }
                ;
            })
        })
        setValue(arr);

    }, [props.audienceState, legislativeDistrictOptions]);

    const onLegislativeFilterChange = (e) => {
        const filter = e.filter;
        // if (filter.value.length < 2) {
        //     setSegmentCategory("");
        //     setSegmentId("");
        //     return;
        // }
        if (filter.value.length === 2) {
            setSegmentCategory(filter.value.toUpperCase());
            setSegmentId("");
        } else if (filter.value.length > 2) {
            //if length > 2 assume query is a list of segment IDs
            setSegmentId(filter.value.toUpperCase());
            setSegmentCategory("");
        }
        // else if (filter.value.length == 0) {
        //     setSegmentCategory("");
        // }
        const allData = legislativeDistrictOptions.slice();
        const newData = filter.value.length >= 1 ? filterBy(allData, filter) : allData;

        setLegislativeDistrictOptions(newData);
    };

    const onLegislativeDistrictChange = (e) => {
        console.log("in onLegislativeDistrictChange:", e);
        let values = [];
        setValue(e.value);
        e.value.forEach((val) => {
            values.push(val.value);
        });
        // props.audienceState.districts = values;
        props.handleAudienceState(prevState => {
            return {...prevState, districts: values}}
        );
    };

    return (
        // <div>
            <div className="form-group">
              <MultiSelect
                  className={"fields legislative-districts-multi-select"}
                  data={legislativeDistrictOptions}
                  autoClose={false}
                  placeholder="Select..."
                  textField="text"
                  dataItemKey="value"
                  filterable={true}
                  onFilterChange={onLegislativeFilterChange}
                  onChange={onLegislativeDistrictChange}
                  size="large"
                  value={value}
                  // style={{width: "32rem"}}
              />
              <Label className={"hint-standard"} >Enter the 2 letter state Abbreviation. FL, AZ, NH</Label>
            </div>
        // </div>
    );
}

export default LegislativeDistricts;
